import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { submitImpressionData } from '../../services/allapis';

const Rightsidebar = () => {
  const [datamsg, setDatamsg] = useState(null); // Initialize datamsg state

  useEffect(() => {
    // Adding the adgebra footer script to the body
    const adgFtSc = document.createElement("script");
    adgFtSc.id = "adg_pixel_footer_script";
    adgFtSc.async = true;
    const adgScParam = "p1=361&p2=0&p3=2&p4=&p5=";
    adgFtSc.src = `https://adgebra.co.in/afpf/afpf.js?${adgScParam}`;
    document.body.appendChild(adgFtSc);

    // Adding the noscript fallback
    const noScript = document.createElement("noscript");
    noScript.innerHTML = `
      <iframe id='adg_footer_pixel_script' src="https://adgebra.co.in/afpf/afpf?p1=361&p2=0&p3=3&p4=&p5=" width="0" height="0" frameBorder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
    `;
    document.body.appendChild(noScript);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(adgFtSc);
      document.body.removeChild(noScript);
    };
  }, []);
  useEffect(() => {
    // Function to handle initial logging on page load
    const logInitialImpressions = async () => {
      try {
        const impressions = [
          { imgvalue: 'Smart_and_Digital' },
        ].map(impression => ({ ...impression, currentPageUrl: window.location.href, click: 0 }));
        // Send the entire impressions array at once
        const result = await submitImpressionData(impressions);
      } catch (error) {
        console.error('Error in initial impression logging:', error);
      }
    };

    logInitialImpressions(); // Call the function immediately on page load
  }, []);
  const handleClick = async (imgvalue, redirectUrl) => {
    try {
      const impressions = [
        { imgvalue: imgvalue },
      ].map(impression => ({ ...impression, currentPageUrl: window.location.href, click: 1 }));
      // Await the result of the asynchronous function
      const result = await submitImpressionData(impressions);

      if (redirectUrl) {
        window.open(redirectUrl, '_blank');
      }
    } catch (error) {
      console.error('Error in handleClick:', error);
    }
  };

  return (
    <>
      <div className="storySectionNav">
        <div className="storySectionNav-left">
          <span>Live TV</span>
        </div>
        <div className="storySectionNav-right">
          <a href="#" className="live-link">Live Video</a>
        </div>
      </div>
      <div className="trendVideo">
        <Link to="/livetv"><img src="/assets/images/livetv.png" alt="" /></Link>   
      </div>
      <br></br>
      <div className="col-md-12 text-center mt-3">
        <a href="#" rel="noopener noreferrer" onClick={(e) => { e.preventDefault(); handleClick('Smart_and_Digital', 'https://cmogujarat.gov.in/en'); }}>
          <img src="/assets/graphics/img/07.jpg" className="gridimg custom-image lazyautosizes lazyloading" style={{ maxWidth: '100%' }} />
        </a>
      </div>
    </>
  );
};

export default Rightsidebar;
