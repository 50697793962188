import React, { useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { UrlLists } from './../utils/utils';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { getTopWebSlider } from '../services/allapis';


const TopWebCarousel = () => {
    const [storydata, setData] = useState(null);
    useEffect(() => {
      const fetchDataFromApi = async () => {
        try {
          const result = await getTopWebSlider();
          setData(result);
        } catch (error) {
          // Handle error
          console.error("Error fetching data:", error);
        }
      };
  
      // Fetch data only if data is null
      if (!storydata) {
        fetchDataFromApi();
      }
    }, [storydata]);

  const data = storydata;
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      partialVisibilityGutter: 40, // Adjust this value as needed
      transitionDuration: 300,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      partialVisibilityGutter: 30, // Adjust this value as needed
      transitionDuration: 300,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      partialVisibilityGutter: 5, // Adjust this value as needed
      transitionDuration: 300,
    },
  };

  const customTransition = "transform 500ms ease-in-out";
  const styles = {
    marginTop: "20px",
  };

  const renderTitle = data ? (
    <>
      <div className="storySectionNav-left">
        <Link to="/web-stories">
          <img src="assets/icons/e-paper-1.svg" alt="" />
          <span>Web Stories</span>
        </Link>
      </div>
      <div className="storySectionNav-right">
        <Link to="/web-stories" style={{ display: 'block' }} className="custom-link-btn">
          <FontAwesomeIcon icon={faChevronRight} />
        </Link>
      </div>
    </>
  ) : null;

  return (
    <>
      <div className="storySectionNav web-story-home">
        {renderTitle}
      </div>
      <div className="carousel-inner-top">
        <Carousel
          responsive={responsive}
          partialVisible={true}
          customTransition={customTransition}
          autoPlay={true}
          autoPlaySpeed={5000}
          infinite={true}
        >
          {data ? (
            data.map((item, index) => (
              <Link
                className="popupbtn"
                to={`/web-stories/${item.slug.replace(/,/g, "/")}`}
                target="_blank"
              >
                <div className="web-story-content">
                  <div className="web-story-card">
                    <button className="popupbtn">
                      <div className="custom-webstory-image">
                        <img
                          src={JSON.parse(item.Story_data)[0][
                            "webimage"
                          ].replace(".webp", "_small.webp")}
                          className="blog-featured-img img-fluid"
                        />
                      </div>
                      <div className="webstory-title-content custom-gujrati-font">
                        <h4>{item.title}</h4>
                      </div>
                    </button>
                  </div>
                </div>
              </Link>
            ))
          ) : (
            <>{UrlLists.Loader}</>
          )}
        </Carousel>
      </div>
    </>
  );
};

export default TopWebCarousel;
