// CategoryPage.js
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getNews } from "../services/allapis";
import { Helmet } from "react-helmet";

import NewsImagesVideos from "./Newsimg";
import NewsShare from "./NewsShare";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faArrowLeftLong,
} from "@fortawesome/free-solid-svg-icons";


const VideoDetail = () => {
  window.scrollTo(0, 0);
  const { slug: currentSlug } = useParams();
  const [data, setData] = useState(null);
  const [catdata, setDatas] = useState(null);
  const [dataNext, setNextData] = useState(null);
  const [dataPre, setPreData] = useState(null);

  useEffect(() => {
    if (currentSlug) {
      const fetchDataFromApi = async () => {
        try {
          const result = await getNews(currentSlug);
          setData(result.newsDetail);
          setDatas(result.categories);
          setNextData(result.nextNews);
          setPreData(result.previousNews);

          localStorage.setItem("newsData", JSON.stringify(result));
        } catch (error) {
          // Handle error
        }
      };
      
      fetchDataFromApi();
    } else {
      const newscachedData = localStorage.getItem("newsData");
      if (newscachedData) {
        const newsparsedData = JSON.parse(newscachedData);
        setData(newsparsedData.newsDetail);
      }
    } 
  }, [currentSlug]);

  
  const metatitle = data?.[0]?.metatitle;
  const metakeyword = data?.[0]?.metakeyword;
  const metadesc = data?.[0]?.metadesc;
  const videoURL = data?.[0]?.videoURL;

  const currentUrl = window.location.href;
  const parts = currentUrl.split('/');
  const dharmaValue = parts[parts.indexOf('videos') + 1];
  return (
    <>
      <Helmet>
          <title>{`${metatitle}`}</title>
          <meta name="keyword" content={`${metakeyword}`} />
          <meta name="description" content={`${metadesc}`} />
          <meta property="og:title" content={`${metatitle}`} />
          <meta property="og:description" content={`${metadesc}`} />
          <meta property="og:video" content={`${videoURL}`} />
          <meta property="og:video:secure_url" content={`${videoURL}`} />
          <meta property="og:video:type" content="video/mp4" />
          <meta property="og:video:width" content="640" />
          <meta property="og:video:height" content="360" />
          <meta property="og:video:alt" content={`${metatitle}`} />
          <meta property="og:url" content={`${currentUrl}`} />
          <meta property="og:type" content="video.other" />
          <meta property="og:site_name" content="GSTV" />
          <meta name="twitter:card" content="player" />
          <meta name="twitter:title" content={`${metatitle}`} />
          <meta name="twitter:description" content={`${metadesc}`} />
          <meta name="twitter:player" content={videoURL} />
          <meta name="twitter:player:stream" content={videoURL} />
          <meta name="twitter:player:stream:content_type" content="video/mp4" />
      </Helmet>
      
      <div className="webstory-fullscreen video-details-fullscreen">
        <div className="custom-web-details">
          <div className="custom-video-popup">
            <div className="back-btn">
            
            {parts.length == '6' ? (
            
                <Link to={`/category/videos/${dharmaValue}`}>
                  <FontAwesomeIcon icon={faArrowLeftLong} />
                </Link>
              ) : (
                <Link to={`/category/videos`}>
                  <FontAwesomeIcon icon={faArrowLeftLong} />
                </Link>
              )}
            </div>

            <div className="custom-btn">
            {dataNext && dataNext.nextnewslug ? (
                <Link to={`/videos/${dataNext.nextnewslug}`}>
              
                  <div className="arrow-up">
                    <button>
                      <FontAwesomeIcon icon={faChevronLeft} />
                    </button>
                  </div>
                </Link>
              ) : (
                <div className="arrow-up disable-arrow-up">
                  <button>
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </button>
                </div>
              )}

              {dataPre && dataPre.prenewslug ? (
                <Link to={`/videos/${dataPre.prenewslug}`}>
                  <div className="arrow-down">
                    <button>
                      <FontAwesomeIcon icon={faChevronRight} />
                    </button>
                  </div>
                </Link>
              ) : (
                <div className="arrow-down disable-arrow-down">
                  <button>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </button>
                </div>
              )}
            </div>
            <div className="frame-content">
              <div className="video-frame">
                <div className="logo-content">
                  <a href="/">
                    <img src="/assets/images/logo.webp" alt="gstv" />
                  </a>
                </div>
                <a href="/" className="more-news-btn">
                  ન્યૂઝ વાંચો
                </a>

                <NewsImagesVideos
                  videoURL={data?.[0]?.videoURL}
                  featureImage={data?.[0]?.featureImage}
                  Title={data?.[0]?.title}
                  imgCls="innerpage"
                />
              </div>
              <div className="video-share-btn">
                <NewsShare NewsLink={currentUrl} NewsTitle={data?.[0]?.title} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoDetail;
