import React, { useRef } from 'react';
import { Link } from 'react-router-dom';

const NewsTags = ({ tags }) => {
    
    const tag = typeof tags === 'string' && tags.trim() !== '' ? tags.split(',').map(url => url.trim()) : [];
    return (
        <>
        {tag.map((tagname, index) => (
        <Link to={`/tags/${tagname}`} className='tagcls'>{tagname}</Link>
      ))}
        </>
    );
};

export default NewsTags;
