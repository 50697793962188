import axios from 'axios';
import { UrlLists } from '../utils/utils';

const api = axios.create({
  baseURL: UrlLists.apiBaseUrl,
});


const headers = {
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods':'GET, POST, PUT, DELETE, OPTIONS',
  'Access-Control-Allow-Headers':'Content-Type, Accept, Authorization, X-Requested-With, X-Token-Auth',
  'Access-Control-Allow-Credentials':'true',
  'Access-Control-Expose-Headers':'Content-Disposition',
  'Access-Control-Max-Age':'86400',
  'Content-Type': 'application/json',
};

const getSettingCategory = async () => { 
  try {
    const response = await api.get('/web/categorysetting', { headers });
    return response.data;
  } catch (error) {
    console.error('Error fetching Categories and Setting:', error);
    throw error;
  }
};

const getHomeData = async () => { 
  try {
    const response = await api.get('/web/home', { headers });
    return response.data;
  } catch (error) {
    console.error('Error fetching Home Data:', error);
    throw error;
  }
};

const getNewsRelated = async (slug) => {
  try {
    const response = await api.get(`/web/news/${slug}`, { headers });
    return response.data;
  } catch (error) {
    console.error('Error fetching News Details:', error);
    throw error;
  }
};

const getTopnews = async (slug) => {
  
  try {
    const response = await api.get(`/home/${slug}`, { headers });
    return response.data;
  } catch (error) {
    console.error('Error fetching Top News:', error);
    throw error;
  }
};

const getCategory = async () => {
  
  try {
    const response = await api.get('/category', { headers });
    return response.data.category;
  } catch (error) {
    console.error('Error fetching Categories:', error);
    throw error;
  }
};

const getHomeCategorys = async () => {
  
  try {
    const response = await api.get('/web/home', { headers });
    return response.data.homecategory;
  } catch (error) {
    console.error('Error fetching top slider:', error);
    throw error;
  }
};

const getHomeTopnews = async () => {
  
  try {
    const response = await api.get('/web/topnews', { headers });
    return response.data.topnews;
  } catch (error) {
    console.error('Error fetching top slider:', error);
    throw error;
  }
};

const getTopSlider = async () => {
  
  try {
    const response = await api.get('/web/topvideo', { headers });
    return response.data.topvideo;
  } catch (error) {
    console.error('Error fetching top slider:', error);
    throw error;
  }
};

const getTopWebSlider = async () => {
  try {
    const response = await api.get('/web/topwebstory', { headers });
    return response.data.topwebstory;
  } catch (error) {
    console.error('Error fetching top slider:', error);
    throw error;
  }
};

const getHomeCategory = async () => {
  
  try {
    const response = await api.get('/homecategory', { headers });
    return response.data;
  } catch (error) {
    console.error('Error fetching Home Category:', error);
    throw error;
  }
};

const getCategoryNews = async (slug,subslug,pageNumber) => {
  try {
    const response = await api.get(`/category/${slug}/${subslug}/${pageNumber}`, { headers });
    return response.data;
  } catch (error) {
    console.error('Error fetching Category News:', error);
    throw error;
  }
};
const getNewsByCategoryId = async (id) => {
  try {
    const response = await api.get(`/homecat/${id}`, { headers });
    return response.data.homecategoryNews;
  } catch (error) {
    console.error('Error fetching Home Category News:', error);
    throw error;
  }
};


const getRelatednews = async (catid,id) => {
  try {
    const response = await api.get(`/category/relatednews/${catid}/${id}`, { headers });
    return response.data;
  } catch (error) {
    console.error('Error fetching Related News:', error);
    throw error;
  }
};

const getNews = async (slug) => {
  try {
    const response = await api.get(`/news/${slug}`, { headers });
    return response.data;
  } catch (error) {
    console.error('Error fetching News Details:', error);
    throw error;
  }
};

const getNewsPreview = async (slug) => {
  try {
    const response = await api.get(`/preview/${slug}`, { headers });
    return response.data;
  } catch (error) {
    console.error('Error fetching News Details:', error);
    throw error;
  }
};

const submitFeedbackData = async (formData) => {
  try {
    const response = await api.post(`/feedback/`,{formData}, { headers });
    return response.data;
  } catch (error) {
    console.error('Error fetching Feedback Details:', error);
    throw error;
  }
};

const submitAstrologyData = async (formData) => {
  try {
    const response = await api.post(`/web/astrology/`,{formData}, { headers });
    return response.data;
  } catch (error) {
    console.error('Error fetching Astrology Details:', error);
    throw error;
  }
};

const submitPollVote = async (formData) => {
  try {
    const response = await api.post(`/poll/vote/`,{formData}, { headers });
    return response.data;
  } catch (error) {
    console.error('Error fetching Poll Vote Details:', error);
    throw error;
  }
};

const insertPageview = async (id) => {
  try {
    const response = await api.post(`/news/${id}`, { headers });
    return response.data;
  } catch (error) {
    console.error('Error Page view Details:', error);
    throw error;
  }
};

const getSetting = async () => {
  try {
    const response = await api.get('/setting', { headers });
    return response.data;
  } catch (error) {
    console.error('Error fetching Setting:', error);
    throw error;
  }
};

const getPoll = async () => {
  try {
    const response = await api.get('/poll', { headers });
     return response.data.poll;
  } catch (error) {
    console.error('Error fetching Poll:', error);
    throw error;
  }
};

const getVotegame = async () => {
  try {
    const response = await api.get('/poll/votegame', { headers });
     return response.data;
  } catch (error) {
    console.error('Error fetching Vote Game:', error);
    throw error;
  }
};

const submitVotegame = async (formData) => {
  try {
    const response = await api.post(`/poll/voteresult`,{formData}, { headers });
    return response.data;
  } catch (error) {
    console.error('Error fetching Poll Vote Details:', error);
    throw error;
  }
};

const getWebstory = async (pageNumber) => {
  try {
    const response = await api.get(`/web/webstory/${pageNumber}`, { headers });
    return response.data;
  } catch (error) {
    console.error('Error fetching Web story:', error);
    throw error;
  }
};

const getWebstoryPreview = async (pageNumber) => {
  try {
    const response = await api.get(`/web/web-stories/preview/${pageNumber}`, { headers });
    return response.data;
  } catch (error) {
    console.error('Error fetching Web story:', error);
    throw error;
  }
};

const getWebstoryDetails = async (slug) => {
  try {
    const response = await api.get(`/web/webstory/${slug}`, { headers });
    return response.data;
  } catch (error) {
    console.error('Error fetching Web story:', error);
    throw error;
  }
};

const getPages = async (slug) => {
  try {
    const response = await api.get(`/pages/${slug}`, { headers });
    return response.data;
  } catch (error) {
    console.error('Error fetching Pages:', error);
    throw error;
  }
};

const getTagNews = async (slug,pageNumber) => {
  try {
    const response = await api.get(`/tags/${slug}/${pageNumber}`, { headers });
    return response.data;
  } catch (error) {
    console.error('Error fetching Category News:', error);
    throw error;
  }
};

const submitGeneralData = async (formData) => {
  try {
    const response = await api.post(`/general/`,{formData}, { headers });
    return response.data;
  } catch (error) {
    console.error('Error fetching general Details:', error);
    throw error;
  }
};

const getpollresult = async () => {
  try {
    const response = await api.get('/poll/pollResult', { headers });
     return response.data;
  } catch (error) {
    console.error('Error fetching Vote Game:', error);
    throw error;
  }
};

const submitLoginData = async (formData) => {
  try {
    const response = await api.post(`/userregister/sendotp`,{formData}, { headers });
    return response.data;
  } catch (error) {
    console.error('Error fetching Login Details:', error);
    throw error;
  }
};

const submitImpressionData = async (impressions) => {
  try {
    const response = await api.post(`/adsimpression`, { impressions }, { headers });
    return response.data;
  } catch (error) {
    console.error('Error submitting impression data:', error);
    throw error;
  }
};

const getsatrangCategory = async () => {
  
  try {
    const response = await api.get('/web/satrangcategory', { headers });
    return response.data.categorychildQuery;
  } catch (error) {
    console.error('Error fetching Categories:', error);
    throw error;
  }
};

const getSatrangSlider = async () => {
  
  try {
    const response = await api.get('/web/satarangCat', { headers });
    return response.data.gstvsatrang;
  } catch (error) {
    console.error('Error fetching top slider:', error);
    throw error;
  }
};

export { getSatrangSlider,getsatrangCategory, getSettingCategory, getVotegame, submitVotegame, getHomeTopnews, getHomeCategorys, getHomeData, getNewsRelated, getRelatednews, getHomeCategory, getNewsByCategoryId, getTagNews, getWebstory, getTopWebSlider, getWebstoryDetails, getNewsPreview, submitPollVote, getTopSlider, insertPageview, getPages, getTopnews, getCategory, getCategoryNews, getNews, getSetting, getPoll , submitFeedbackData, submitAstrologyData, getWebstoryPreview, submitGeneralData, getpollresult, submitLoginData, submitImpressionData };
