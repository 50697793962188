import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getWebstory,submitImpressionData } from "../services/allapis";
import { Helmet } from "react-helmet";
import InfiniteScroll from "react-infinite-scroll-component";
import { truncateTitle, UrlLists } from "./../utils/utils";
import WebPopup from "./WebStorypopup";

const Webstory = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [popupOpen, setPopupOpen] = useState(false);
  const [popupData, setPopupData] = useState(null);
  const [webs, setWebs] = useState([]);
  const [currentWebIndex, setCurrentWebIndex] = useState(0);

  const fetchData = async (pageNumber) => {
    try {
      const result = await getWebstory(pageNumber);
      const webstoryData = result.webstory.data;
      if (webstoryData.length > 0) {
        setData((prevData) => [...prevData, ...webstoryData]);
        setPage(page + 1); // Increment the page for the next potential request
      } else {
        setHasMore(false); // No more data, set hasMore to false
      }
      if (result.webstory.data) {
        setWebs(result.webstory.data);
      }
    } catch (error) {
      // Handle error
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (page === 1) {
      window.scrollTo(0, 0);
      const fetchDataFromApi = async () => {
        try {
          const result = await getWebstory(1);
          setData(result.webstory.data);
          if (result.webstory.data) {
            setWebs(result.webstory.data);
          }
        } catch (error) {
          // Handle error
        }
      };

      fetchDataFromApi();
    }
  }, []);

  const handleFetchData = () => {
    if (hasMore) {
      fetchData(page + 1);
    }
  };

  const handlePopupOpen = (item) => {
    setPopupData(item);
    setPopupOpen(true);
    const newUrl = `/web-stories/${item.slug}`;
    window.history.pushState(null, "", newUrl);
    sessionStorage.setItem("popupOpen", "true");
    sessionStorage.setItem("popupData", JSON.stringify(item));
  };

  const handlePopupClose = () => {
    setPopupOpen(false);
    setPopupData(null);
    sessionStorage.removeItem("popupOpen");
    sessionStorage.removeItem("popupData");
  };

  const updateUrl = (web) => {
    const newUrl = `/web-stories/${web.slug}`;
    window.history.pushState(null, "", newUrl);
  };

  const handleNextWeb = () => {
    const newIndex = (currentWebIndex + 1) % webs.length;
    setCurrentWebIndex(newIndex);
    updateUrl(webs[newIndex]);
  };

  const handlePreviousWeb = () => {
    const newIndex = (currentWebIndex - 1 + webs.length) % webs.length;
    setCurrentWebIndex(newIndex);
    updateUrl(webs[newIndex]);
  };
  useEffect(() => {
    // Function to handle initial logging on page load
    const logInitialImpressions = async () => {
      try {
        const impressions = [
          { imgvalue: 'Prakrutik_kheti' },
          { imgvalue: 'U_S_Dollar' },
          { imgvalue: 'Milk_utpadan' },
          { imgvalue: 'MSME_Ekmo' },
          { imgvalue: 'Smart_and_Digital' },
          { imgvalue: 'Vidhya_smiksha_candra' },
        ].map(impression => ({ ...impression, currentPageUrl: window.location.href, click: 0 }));
  
        // Send the entire impressions array at once
        const result = await submitImpressionData(impressions);
      } catch (error) {
        console.error('Error in initial impression logging:', error);
      }
    };

    logInitialImpressions(); // Call the function immediately on page load
  }, []);
  const handleClick = async (imgvalue, redirectUrl) => {
    try {
      const impressions = [
        { imgvalue: imgvalue },
      ].map(impression => ({ ...impression, currentPageUrl: window.location.href, click: 1 }));
      // Await the result of the asynchronous function
      const result = await submitImpressionData(impressions);

      if (redirectUrl) {
        window.open(redirectUrl, '_blank');
      }
    } catch (error) {
      console.error('Error in handleClick:', error);
    }
  };
  if (!data) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <div className="row blog-content adscls">
          <div className="col-md-4"><a href="#" onClick={(e) => { e.preventDefault(); handleClick('Prakrutik_kheti', 'https://cmogujarat.gov.in/en'); }}><img src="/assets/graphics/img/12.jpg" className="gridimg custom-image lazyautosizes lazyloading" width="100%" /></a></div>
          <div className="col-md-4"><a href="#" onClick={(e) => { e.preventDefault(); handleClick('U_S_Dollar', 'https://cmogujarat.gov.in/en'); }}><img src="/assets/graphics/img/10.jpg" className="gridimg custom-image lazyautosizes lazyloading" width="100%" /></a></div>
          <div className="col-md-4"><a href="#" onClick={(e) => { e.preventDefault(); handleClick('Milk_utpadan', 'https://cmogujarat.gov.in/en'); }}><img src="/assets/graphics/img/11.jpg" className="gridimg custom-image lazyautosizes lazyloading" width="100%" /></a></div>   
      </div>
      <div className="row blog-content adsmobilecls">
        <div className="col-md-3"><a href="#" onClick={(e) => { e.preventDefault(); handleClick('Prakrutik_kheti', 'https://cmogujarat.gov.in/en'); }}><img src="/assets/graphics/img/12.jpg" className="gridimg custom-image lazyautosizes lazyloading" width="100%" /></a></div>
      </div>
      <div className="blogs-main-section-inner">
        <div className="blogs-head-bar first ">
          <span className="blog-category">Web Stories</span>
        </div>
        
        <Helmet>
          <title>Web Stories</title>
          <meta name="description" content="Web Stories" />
        </Helmet>

        <InfiniteScroll
          dataLength={data.length}
          next={handleFetchData}
          hasMore={hasMore}
          loader={<>{UrlLists.Loader}</>}
        >
          <div className="row blog-content web-story-content">
            {data.map((item, index) => {
              return (
                <div key={index} className="col-lg-3 col-sm-4 col-6 web-story-card p-2">
                  <Link
                    className="popupbtn"
                    to={`/web-stories/${item.slug.replace(/,/g, "/")}`}
                  >
                    <div className="custom-webstory-image">
                      <img
                        src={JSON.parse(item.Story_data)[0]["webimage"].replace(
                          ".webp",
                          "_small.webp"
                        )}
                        className="blog-featured-img img-fluid"
                      />
                    </div>
                    <div className="webstory-title-content custom-gujrati-font">
                      <h4>{item.title}</h4>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </InfiniteScroll>
        {popupOpen && popupData && (
          <WebPopup
            Images={popupData.videoURL}
            webTitle={popupData.title}
            onClose={handlePopupClose}
            handlePreviousWeb={handlePreviousWeb}
            handleNextWeb={handleNextWeb}
            currentWebIndex={currentWebIndex}
            webs={webs}
          />
        )}
      </div>
      <div className="row blog-content adscls">
          <div className="col-md-4"><a href="#" onClick={(e) => { e.preventDefault(); handleClick('MSME_Ekmo', 'https://cmogujarat.gov.in/en'); }}><img src="/assets/graphics/img/09.jpg" className="gridimg custom-image lazyautosizes lazyloading" width="100%" /></a></div>
          <div className="col-md-4"><a href="#" onClick={(e) => { e.preventDefault(); handleClick('Smart_and_Digital', 'https://cmogujarat.gov.in/en'); }}><img src="/assets/graphics/img/07.jpg" className="gridimg custom-image lazyautosizes lazyloading" width="100%" /></a></div>
          <div className="col-md-4"><a href="#" onClick={(e) => { e.preventDefault(); handleClick('Vidhya_smiksha_candra', 'https://cmogujarat.gov.in/en'); }}><img src="/assets/graphics/img/08.jpg" className="gridimg custom-image lazyautosizes lazyloading" width="100%" /></a></div>   
      </div>
      <div className="row blog-content adsmobilecls">
        <div className="col-md-3"><a href="#" onClick={(e) => { e.preventDefault(); handleClick('Vidhya_smiksha_candra', 'https://cmogujarat.gov.in/en'); }}><img src="/assets/graphics/img/08.jpg" className="gridimg custom-image lazyautosizes lazyloading" width="100%" /></a></div>
      </div>
    </>
  );
};

export default Webstory;
