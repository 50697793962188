// CategoryPage.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getPages } from '../services/allapis';
import { Helmet } from 'react-helmet';

const Pages = () => {
  const { slug: currentSlug } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Check if the current slug is different from the previous one
    if (currentSlug) {
      window.scrollTo(0, 0);
      const fetchDataFromApi = async () => {
        try {
          const result = await getPages(currentSlug);
          setData(result.pageDetail);
        } catch (error) {
          // Handle error
        } finally {
          setLoading(false);
        }
      };

      fetchDataFromApi();
    }
  }, [currentSlug]);

  useEffect(() => {
    // Redirect if data is loaded but empty or if there's an error
    if (!loading && (!data || !data[0] || !data[0].title)) {
      window.location.href = `https://www.gstv.in/`;
    }
  }, [data, loading, currentSlug]);

  const metatitle = data?.[0]?.metatitle;
  const metadesc = data?.[0]?.metadesc;

  return (
    <>
      <Helmet>
        <title>{`${metatitle}`}</title>
        <meta name="description" content={`${metadesc}`} />
      </Helmet>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div className="blogs-main-section inner">
          <div className="detail-page-heading-h1">
            <h1 className='content-page-title'>{data?.[0]?.title}</h1>
          </div>
          <div className="row blog-content">
            <div className="col-lg-12 detail-page custom-content-page">
              <div className="blog-read-content">
                <div className="detail-page custom-content-text" dangerouslySetInnerHTML={{ __html: data?.[0]?.description }} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Pages;
