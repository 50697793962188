import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';

import Home from '../views/Home';
import News from '../views/News';
import Rightsidebar from '../views/layouts/Rightsidebar';
import Header from './../views/layouts/Header';
import Poll from './../views/layouts/Poll';
import Sidebar from './../views/layouts/Sidebar';
import Category from '../views/Category';
import Pages from '../views/Pages';

// import Astrology from '../views/layouts/Astrology';
import Feedback from '../views/layouts/Feedback';
import Address from '../views/layouts/Address';
import NewsPreview from '../views/NewsPreview';
import Webstory from '../views/Webstory';
import WebstoryPreview from '../views/WebstoryPreview';
import Tags from '../views/Tags';
import WebstoryDetail from '../views/WebstoryDetail';
//import Astrologypopup from '../views/Astrologypopup';
import VideoDetail from '../views/Videopopup';
import Footer from '../views/layouts/Footer';
import Votes from '../views/Vote';
import VoteResult from '../views/VoteResult';
import Livevideo from '../views/Livevideo';
import Election from '../views/election';
import Pollresult from '../views/pollresult';
import Login from '../views/Login';



const AppRouter = () => {
  
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

const AppContent = () => {
  const location = useLocation();
  const [isWebstoryDetailPage, setIsWebstoryDetailPage] = useState(false);
  const [isvideoDetailPage, setIsvideoDetailPage] = useState(false);
  const [isLoginPage, setIsLoginPage] = useState(false);

  useEffect(() => {
    setIsWebstoryDetailPage(location.pathname.startsWith('/web-stories/'));
    setIsvideoDetailPage(location.pathname.startsWith('/videos/'));
    setIsLoginPage(location.pathname === '/user/login');
  }, [location.pathname]);

  return (
    <>
      {isWebstoryDetailPage ? (
        <Routes>
          <Route path="/web-stories/:slug" element={<WebstoryDetail />} />
          <Route path="/web-stories/preview" element={<WebstoryPreview />}>
            <Route index element={<WebstoryPreview />} />
            <Route path="/web-stories/preview/:slug" element={<WebstoryPreview />} />
          </Route>
        </Routes>
      ) : isvideoDetailPage ? (
        <Routes>
          <Route path="/videos/:subcategory?/:slug" element={<VideoDetail />} />
        </Routes>
      )  : isLoginPage ? (
          <Routes>
            <Route path="/user/login" element={<Login />} />
          </Routes>
      ) : (
        <>
            <Header />
            
            <div className=" contents-main-div">
                <div className="row left-nav">
                  <Sidebar />
                  <div className="offset-md-2 col-md-7 col-lg-7 Center-main-div">
                  
                 {/* <Astrologypopup /> */}
                  <Routes>
                    <Route path="/" element={<Home />} />
                      <Route path="/category" element={<Category />}>
                        <Route index element={<Category />} />
                        <Route path=":slug" element={<Category />}>
                          <Route path=":subcatslug" element={<Category />} />
                        </Route>
                      </Route>
                      <Route path="/tags" element={<Tags />}>
                        <Route index element={<Tags />} />
                        <Route path=":slug" element={<Tags />} />
                      </Route>
                      <Route path="/news" element={<News />}>
                        <Route index element={<News />} />
                        <Route path=":category/:subcategory?/:subcategory?/:slug" element={<News />} />
                      </Route>
                      <Route path="/web-stories" element={<Webstory />} />
                      <Route path="/web-stories/:slug" element={<WebstoryDetail />} />
                      <Route path="/web-stories/preview" element={<WebstoryPreview />}>
                        <Route index element={<WebstoryPreview />} />
                        <Route path="/web-stories/preview/:slug" element={<WebstoryPreview />} />
                      </Route>
                      <Route path="/preview" element={<NewsPreview />}>
                        <Route index element={<NewsPreview />} />
                        <Route path=":slug" element={<NewsPreview />} />
                      </Route>
                    <Route path=":slug" element={<Pages />} />
                    <Route path="/votes" element={<Votes />}></Route>
                    <Route path="/votes-result" element={<VoteResult/>}></Route>
                    <Route path="/livetv" element={<Livevideo />}></Route>
                    <Route path="/election-2024" element={<Election />}></Route>
                    <Route path="/poll-result" element={<Pollresult />}></Route>
                  </Routes>
                </div>

                <div className="col-md-3 col-lg-3 right-sidebar">
                    <Rightsidebar/>
                    <Poll />                    
                    <Feedback />
                    <Address />
                </div>
              </div>
              
            </div>
            <div className='col-sm-12 col-md-12'>
            <Footer />
            </div>
            </>
      )}
    </>
  );
};

export default AppRouter;