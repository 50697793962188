import React, { useEffect, useState } from 'react';
import { getVotegame, submitVotegame } from '../services/allapis';

const VoteResult = () => {
  const [data, setData] = useState(null);
  const [dataresult, setVoteData] = useState(null);
  const [datamsg, setDatamsg] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showMessage, setShowMessage] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getVotegame();
        setData(result.votegame);
        setVoteData(result.voteresults);
        setDatamsg(result.msgvote);
        
      } catch (error) {
        // Handle error
      }
    };

    if (!data) {
      fetchData();
    }
  }, []); // Fetch data only when data changes

  const hideMessageAfterDelay = () => {
    setTimeout(() => {
      setShowMessage(false);
      setSelectedOption(null);
    }, 3000);
  };

  const handleVote = async (votegameID, optionId) => {
    try {
      setSelectedOption(optionId);
      const result = await submitVotegame({
        votegameID,
        userID: '',
        selectedAnswer: optionId,
        IPaddress: '',
      });
      setShowMessage(true);
      setVoteData(result.voteresults);
      setDatamsg(result.msgvote);
      hideMessageAfterDelay();
    } catch (error) {
      // Handle error
    }
  };

  const message = datamsg;

  return (
    <>
      {data ? (
        data.map((item) => (
          <div key={item.id} className="blogs-main-section-inner">
        <div className="blogs-head-bar first">
          <span className="blog-category">Results</span>
        </div>
         
            <div className="row blog-content pt-3">
              <div className="col-lg-12 detail-page custom-content-page">
                <div className="blog-read-content">
                  {/* {showMessage && message && <p className="MCQ-msg">{message}</p>}
                  {item.answerOption.split(':').map((option, index) => (
                    <div key={index} className="all-options">
                      <input
                        type="radio"
                        name="pollOption"
                        checked={selectedOption === option}
                        id={option}
                        value={index} // Change value to index or option ID
                        onChange={() => handleVote(item.id, option)}
                      />
                      <label htmlFor={option}>{option}</label>
                    </div>
                  ))} */}
                  { <>
                  {dataresult && dataresult.length > 0 ? (
                    <>
                  <div className="detail-page-heading-h1">
                    <h4>{item.question}</h4>
                  </div>
                  {dataresult.map((val, index) => (
                    <div key={index} className="all-options">
                      {val.selectedAnswer} : {val.answerCount}
                    </div>
                                 
                  ))}
                  </>     
                ) : (
                  <></>
                )}
                  </> }
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <></>
      )}
    </>
  );
};

export default VoteResult;
