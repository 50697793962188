import React from 'react';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faXTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faLink } from '@fortawesome/free-solid-svg-icons';


const NewsShare = ({ NewsLink, NewsTitle }) => {
    
    const twiiterStyle = {
        color: '#007BFF', 
        textDecoration: 'none',
    };
    const facebookStyle = {
        color:'#3a5598',
        textDecoration: 'none',
    };
    const linkStyle = {
        color:'#e31e25',
        textDecoration: 'none',
        cursor: 'pointer',
    };
    const btnStyle = {
        border:'none',
        background: 'none',
        cursor: 'pointer',
        color: '#128C7E',
        padding: 0,
    };
    const handleCopy = () => {
        toast.success('URL copied to clipboard!', { position: 'bottom-center' });
    };
    const handleShare = () => {
        const encodedTitle = encodeURIComponent(NewsTitle);
        const encodedLink = encodeURIComponent(NewsLink);
        const whatsappUrl = `https://api.whatsapp.com/send?text=${encodedTitle}%0A${encodedLink}`;
        window.open(whatsappUrl, '_blank');
    };
   
    return (
        <>
        <div className="blog-like-share-save"><span className="custom-link-text">Share With:</span> 
        <FacebookShareButton url={NewsLink} quote={NewsTitle} style={facebookStyle} className='facebook-link'><FontAwesomeIcon icon={faFacebookF} /></FacebookShareButton>
        <TwitterShareButton url={NewsLink} title={NewsTitle} style={twiiterStyle} className='twitter-link'><FontAwesomeIcon icon={faXTwitter} /></TwitterShareButton>
        <button onClick={handleShare} style={btnStyle} className='whatsapp-link'><FontAwesomeIcon icon={faWhatsapp} /></button>
        <CopyToClipboard text={NewsLink} onCopy={handleCopy} style={linkStyle} className="copy-link"><FontAwesomeIcon icon={faLink} /></CopyToClipboard>
        </div>
        <ToastContainer position="bottom-center" autoClose={3000} />
        </>
    );
};

export default NewsShare;