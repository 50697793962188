import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'

const WebPopup = ({ Images, webTitle, onClose, handlePreviousWeb, handleNextWeb, currentWebIndex, webs }) => {
  if (!webs || webs.length === 0 || currentWebIndex >= webs.length) {
    return null;
  }

  const web = webs[currentWebIndex];
  const storydata = JSON.parse(web.Story_data || '[]');
  return (
    <div className="popup-fullscreen">
      <button className='popupclose' onClick={onClose}>X</button>
      <div className="row">
        <div className='col-lg-12 text-center' style={{ height: '85vh' }}>
          <button disabled={currentWebIndex === 0} onClick={handlePreviousWeb}>Previous</button>
          <button disabled={currentWebIndex === webs.length - 1} onClick={handleNextWeb}>Next</button>
          <Carousel interval={5000} showArrows={true} showThumbs={false} infiniteLoop={true} autoPlay={true} showStatus={false}>
            {storydata.map((image, index) => (
              <div key={index}>
                <img src={image.webimage.replace('.webp', '_medium.webp')} alt={image.webtitles} />
                {image.webtitles && <p className="legend">{image.webtitles}</p>}
              </div>
            ))}
          </Carousel>
          <h2>{webTitle}</h2>
        </div>
      </div>
    </div>
  );
};

export default WebPopup;
