import React, { useEffect } from 'react';
import TopCarousel from './TopCarousel';
import Topnews from './Topnews';
import TopWebCarousel from './TopWebCarousel';
import TopCategoryCarousel from './TopCategoryCarousel';
import HomeCategorynews from './HomeCategory';
import { submitImpressionData } from '../services/allapis';

const Home = () => {
  
  useEffect(() => {
    // Function to handle initial logging on page load
    const logInitialImpressions = async () => {
      try {
        const impressions = [
          { imgvalue: 'Riverfront' },
          { imgvalue: 'Aarogyaprad_Bhojan' },
          { imgvalue: 'Renewable_energy' },
          { imgvalue: 'Nmo_Lakshmi_Yojana' },
          { imgvalue: 'Cashless_sarvar' },
          { imgvalue: 'Nmo_Sarsavati_Yojana' },
        ].map(impression => ({ ...impression, currentPageUrl: window.location.href, click: 0 }));
  
        // Send the entire impressions array at once
        const result = await submitImpressionData(impressions);

       } catch (error) {
        console.error('Error in initial impression logging:', error);
      }
    };

    logInitialImpressions(); // Call the function immediately on page load
  }, []);

  const handleClick = async (imgvalue, redirectUrl) => {
    try {
      // Log the image value
      const impressions = [
        { imgvalue: imgvalue },
      ].map(impression => ({ ...impression, currentPageUrl: window.location.href, click: 1 }));
      // Await the result of the asynchronous function
      const result = await submitImpressionData(impressions);
      window.open(redirectUrl, '_blank');
    } catch (error) {
      console.error('Error in handleClick:', error);
    }
  };

  return (
    <>
      <TopCarousel />
      
      <Topnews />
      <TopWebCarousel />
      {/* <div className="row blog-content adscls">
        <div className="col-md-4"><a href="#" onClick={(e) => { e.preventDefault(); handleClick('Nmo_Lakshmi_Yojana', 'https://cmogujarat.gov.in/en'); }}><img src="/assets/graphics/img/04.jpg" className="gridimg custom-image lazyautosizes lazyloading" width="100%" /></a></div>
        <div className="col-md-4"><a href="#" onClick={(e) => { e.preventDefault(); handleClick('Cashless_sarvar', 'https://cmogujarat.gov.in/en'); }}><img src="/assets/graphics/img/05.jpg" className="gridimg custom-image lazyautosizes lazyloading" width="100%" /></a></div>
        <div className="col-md-4"><a href="#" onClick={(e) => { e.preventDefault(); handleClick('Nmo_Sarsavati_Yojana', 'https://cmogujarat.gov.in/en'); }}><img src="/assets/graphics/img/06.jpg" className="gridimg custom-image lazyautosizes lazyloading" width="100%" /></a></div>
      </div>
      <div className="row blog-content adsmobilecls">
        <div className="col-md-3"><a href="#" onClick={(e) => { e.preventDefault(); handleClick('Nmo_Lakshmi_Yojana', 'https://cmogujarat.gov.in/en'); }}><img src="/assets/graphics/img/04.jpg" className="gridimg custom-image lazyautosizes lazyloading" width="100%" /></a></div>
      </div> */}
      <TopCategoryCarousel />
      <HomeCategorynews />
    </>
  );
};

export default Home;
