import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const Livevideo = () => {
  const location = useLocation();
  const urlpath = location.pathname.startsWith("/videos/");

  useEffect(() => {
    if (!urlpath) {
      // Load the first script
      const script1 = document.createElement("script");
      script1.src = "//imasdk.googleapis.com/js/sdkloader/ima3.js";
      document.body.appendChild(script1);

      // Return a cleanup function to remove the first script
      return () => {
        document.body.removeChild(script1);
      };
    }
  }, [urlpath]); // Make sure to include urlpath in the dependency array

  useEffect(() => {
    // Load the second script
    const script2 = document.createElement("script");
    script2.src = "//player.livecast.io/dist/js/livecast_player.min.js";
    document.body.appendChild(script2);

    // Return a cleanup function to remove the second script
    return () => {
      document.body.removeChild(script2);
    };
  }, []); // This effect will only run once, when the component mounts

  const containerStyles = {
    height: '100vh',
    width: '100%',
  };

  return (
    <>
    <div className="blogs-main-section-inner">
      <div className="blogs-head-bar first ">
        <span className="blog-category">Live TV</span>
      </div>

        <div className="row blog-content ">
         
              <div className="col-12 p-2">
                
                  
                  <div className="custom-gujrati-font">
                  <div className="lc-embed-responsive lc-embed-responsive-16by9">
                <div className="livecast_player">
                  <div className="livecast_player_eb cid_21_stream_24_gstv" style={containerStyles}></div>
                </div>
              </div>
                  </div>
                
              </div>
         
        </div>
      
    </div>
    
    </>
  );
};

export default Livevideo;
