import React, { useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { DateComponent, ReadingTimeCalculator, UrlLists, truncateTitle, truncateDescription } from './../utils/utils';
import NewsShare from './NewsShare';
import NewsImagesVideos from './Newsimg';
import { getHomeTopnews } from '../services/allapis';

const Topnews = () => {
  const [topnewsData, setData] = useState(null);
    useEffect(() => {
      const fetchDataFromApi = async () => {
        try {
          const result = await getHomeTopnews();
          setData(result);
        } catch (error) {
          // Handle error
          console.error("Error fetching data:", error);
        }
      };
  
      // Fetch data only if data is null
      if (!topnewsData) {
        fetchDataFromApi();
      }
    }, [topnewsData]);
  
  const data = topnewsData;
  
  const dataCount = data ? data.length : 0;
  if (dataCount === 0) {
    return [];
  }
  return (
    <div className="blogs-main-section">
    <div className="blogs-head-bar first ">
        <span className="blog-category">Top News</span>
    </div>
    <div className="row blog-content">
    {data ? (data.map((item) => (
    <div className="col-lg-6 ">
    <div className="blog-read-content custom-top-news">
    <Link to={`/news/${item.category_slugs ? item.category_slugs.replace(/,/g, '/') : ''}/${item.slug}`}> 
      <h4 className='custom-blog-title'>{truncateTitle(item.title, 15)}</h4>
      <div className="hover-image"><NewsImagesVideos videoURL={item.videoURL} featureImage={item.featureImage} Title={item.title} imgCls="gridimg" /></div>
      <p className="blog-excerpt" dangerouslySetInnerHTML={{ __html: truncateDescription(item.description, 25) }} /></Link>
      <span className="last-update-blog">Last Update : <DateComponent formattedDate={item.created_at} /></span>
      <div className="blog-featured-functions">
          <div className="reading-time-blog">
          <ReadingTimeCalculator content={item.description} wordsPerMinute={100} />
          </div>
          <NewsShare NewsLink={`${UrlLists.domainURL}/news/${item.category_slugs ? item.category_slugs.replace(/,/g, '/') : ''}/${item.slug}`} NewsTitle={item.title} />
      </div>
    </div>
  </div>
     ))
     ) : (
      <>{UrlLists.Loader}</>
     )}
    </div>
  </div>
  );
};

export default Topnews;
