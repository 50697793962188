import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getTagNews } from '../services/allapis';
import { Helmet } from 'react-helmet';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ReadingTimeCalculator, truncateTitle, truncateDescription, UrlLists, BookmarkButton, DateComponent } from './../utils/utils';
import NewsImagesVideos from './Newsimg';
import NewsShare from './NewsShare';
const Tags = () => {
 // window.scrollTo(0, 0);
  const { slug: currentSlug } = useParams();
  const [prevSlug, setPrevSlug] = useState(null);
  const [data, setData] = useState([]);
  const [dataCatTitle, setCatTitle] = useState(null);
  const [dataCatSlug, setCatSlug] = useState(null);
  const [datametaTitle, setMetatitle] = useState(null);
  const [datametadesc, setMetadesc] = useState(null);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);

  const fetchData = async (pageNumber) => {
    try {
      const result = await getTagNews(currentSlug, pageNumber);
      const newData = result.news.data;
      if (newData.length > 0) {
        setData((prevData) => [...prevData, ...newData]);
        setPage(page + 1); // Increment the page for the next potential request
      } else {
        setHasMore(false); // No more data, set hasMore to false
      }
    } catch (error) {
      // Handle error
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (currentSlug && currentSlug !== prevSlug) {
      const fetchDataFromApi = async () => {
        try {
          const result = await getTagNews(currentSlug,1);
          setData(result.news.data);
          setCatTitle(result.catTitle);
          setCatSlug(result.catSlug);
          setMetatitle(result.catMetatitle);
          setMetadesc(result.catMetadesc);
          setPrevSlug(currentSlug);
        } catch (error) {
          // Handle error
        }
      };

      fetchDataFromApi();
    }
  }, [currentSlug, prevSlug, datametaTitle, datametadesc, dataCatTitle, dataCatSlug]);

  const handleFetchData = () => {
    if (hasMore) {
      fetchData(page + 1);
    }
  };

  

  return (
    <div className="blogs-main-section-inner">
    <div className="blogs-head-bar first ">
        <span className="blog-category">{dataCatTitle}</span>
        <a href="#"><img className ="blog-arrow" src="/assets/icons/common-arrow.png" /></a>
    </div>
    
      <Helmet>
        <title>{`${datametaTitle}`}</title>
        <meta name="description" content={`${datametadesc}`} />
      </Helmet>

      <InfiniteScroll
        dataLength={data.length}
        next={handleFetchData}
        hasMore={hasMore}
        loader={<>{UrlLists.Loader}</>}
      >
        <div className="row blog-content">
        {data.map((item) => (
          item.catID === '9' ? (
            <div className="col-lg-3"><Link to={`/news/${item.slug}`}>
              <div className="blog-read-content">  
                <h4>{truncateTitle(item.title, 100)}</h4>
                <NewsImagesVideos videoURL={item.videoURL} featureImage={item.featureImage} Title={item.title} imgCls="videogridimg" />
              </div></Link>
            </div>
            ) : (
           <div className="col-lg-6 ">
              <div className="blog-read-content">  
                <h4><Link to={`/news/${item.slug}`}>{truncateTitle(item.title, 15)}</Link></h4>
                <NewsImagesVideos videoURL={item.videoURL} featureImage={item.featureImage} Title={item.title} imgCls="gridimg" />
                <p className="blog-excerpt" dangerouslySetInnerHTML={{ __html: truncateDescription(item.description, 25) }} />
                <span className="last-update-blog">Last Update : <DateComponent formattedDate={item.created_at} /></span>
                <div className="blog-featured-functions">
                    <div className="reading-time-blog">
                    <ReadingTimeCalculator content={item.description} wordsPerMinute={100} />
                    </div>
                    <NewsShare NewsLink={`${UrlLists.domainURL}/news/${item.slug}`} NewsTitle={item.title} />
                </div>
              </div>
            </div>
          )
        ))}
        </div>
      </InfiniteScroll>
    
    </div>
  );
};

export default Tags;
