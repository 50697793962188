// components/Sidebar.js
import React, { useEffect, useState } from "react";

import { getSettingCategory } from "../../services/allapis";
import { Link, useLocation } from "react-router-dom";
import { UrlLists } from "../../utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faXTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const Sidebar = () => {
  const [data, setData] = useState(null);
  const [isActive, setActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const storedCategoryData = sessionStorage.getItem("MenuCategory");
    if (storedCategoryData !== null) {
      const categoriesdata = JSON.parse(storedCategoryData);
      setData(categoriesdata);
    } else {
      if (!data) {
        setIsLoading(true);
        const fetchDataFromApi = async () => {
          try {
            const result = await getSettingCategory();
            setData(result.category);
            setIsLoading(false);
            localStorage.setItem("sidebarData", JSON.stringify(result));
          } catch (error) {
            // Handle error
          } finally {
            setIsLoading(false);
          }
        };

        fetchDataFromApi();
      } 
    }
  }, []);
  const iconurl = UrlLists.catIcon;
  const [expandedItems, setExpandedItems] = useState([]);

  const handleToggle = (parentID) => {
    setExpandedItems((prevExpandedItems) => {
      if (prevExpandedItems.includes(parentID)) {
        // If already open, close it
        return [];
      } else {
        // If closed, open it
        return [parentID];
      }
    });
  };

  const handleToggles = () => {
    setActive(!isActive);
  };

  const isExpanded = (parentID) => expandedItems[0] === parentID;
  const ParentComponent = ({ data }) => {
    // Ensure that data is not null or undefined
    if (!data) {
      return <p>Loading...</p>;
    }

    return (
      <>
        <ul className="sidebar-menu">
        {data
          .filter((item) => item.parentID === 0)
          .map((parentItem) => (
            <>
              
                <li>
                  
                <Link key={parentItem.id} 
                to={`/category/${parentItem.slug}`}  
                  className={`${location.pathname === `/category/${parentItem.slug}` ? ' active' : ''}`}>
                  {parentItem.icon && (
                    <img
                      src={`${iconurl}${parentItem.icon}`}
                      alt={parentItem.title}
                    />
                  )}
                  <span>{parentItem.title}</span>
                </Link>
                {data.some(
                  (childItem) => childItem.parentID === parentItem.id
                ) && (
                  <span
                    className="togglemenucls"
                    onClick={() => handleToggle(parentItem.id)}
                  >
                    {isExpanded(parentItem.id) ? (
                        <FontAwesomeIcon icon={faChevronLeft} />
                      ) : (
                        <FontAwesomeIcon icon={faChevronRight} />
                      )}
                  </span>
                )}
                {isExpanded(parentItem.id) && (
                  <div className="submenucls">
                    {data
                      .filter(
                        (childItem) => childItem.parentID === parentItem.id
                      )
                      .map((childItem) => (
                        <Link
                          className={`subcatCls ${location.pathname === `/category/${parentItem.slug}/${childItem.slug}` ? ' active' : ''}`}
                          key={childItem.id}
                          to={`/category/${parentItem.slug}/${childItem.slug}`}
                        >
                          {childItem.icon && (
                            <img
                              src={`${iconurl}${childItem.icon}`}
                              alt={childItem.title}
                            />
                          )}
                          <span>{childItem.title}</span>
                        </Link>
                      ))}
                  </div>
                )}
                </li>
            </>
          ))}
          
        <li>
          <Link to="/web-stories" className={`${location.pathname === `/web-stories` ? ' active' : ''}`}>
            <img src="/assets/icons/side-web-stories.svg" alt="Web Stories" />
            <span>Web Stories</span>
          </Link>
        </li>
        </ul>
      </>
    );
  };

  return (
    <div className="sidebar col-lg-2 col-md-2 ">
      <div className="nav-list custom-sidebar scrollarea">
        <ParentComponent data={data} />
        <div className="download-app">
        <h6>Download App From</h6>
        <div className="download-btn">
        <a href="https://play.google.com/store/apps/details?id=com.tops.gstvapps" target="_blank">
          <img src="/assets/images/play-store.png" alt="Play Store" />
        </a>
        <a href="https://apps.apple.com/in/app/gstv-gujarat-samachar/id1609602449" target="_blank"> 
          <img src="/assets/images/appstore.png" alt="Play Store" />
        </a>
        </div>
        <h6>Follow us on</h6>
        <div className="socila-media">
          <a href="https://www.facebook.com/GSTV.NEWS" target="_blank">
            <FontAwesomeIcon icon={faFacebookF} className="facebook-link"/>
          </a>
          <a href="https://twitter.com/GSTV_NEWS" target="_blank">
            <FontAwesomeIcon icon={faXTwitter} className="twitter-link"/>
          </a>
          <a href="https://www.instagram.com/gstvnews" target="_blank">
            <FontAwesomeIcon icon={faInstagram} className="instagram-link"/>
          </a>
          <a href="https://www.youtube.com/gstvnews" target="_blank">
            <FontAwesomeIcon icon={faYoutube} className="youtube-link"/>
          </a>
        </div>
      </div>
      </div>
      
    </div>
  );
};

export default Sidebar;
