import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import AppRouter from './routes/AppRouter';

function App() {
    return (
      <AppRouter />
  );
}

export default App;
