import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
const HelmetTag = (props) =>{
const { image } = props
return(
    <Helmet>
          <title>{`News head`}</title>
          <meta name="keyword" content={`TEST`} />
          <meta name="description" content={`test`} />
          <meta property="og:url" content={`https://www.gstv.in/news/india/loksabha-election-2024/this-high-profile-seat-will-be-watched-in-the-third-phase-of-the-lok-sabha-elections`} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={`News test`} />
          <meta property="og:description" content={`news test`} />
          <meta property="og:image" content={`${image}`} />
          {/* {fileTypeMetaTag}
          <meta name="twitter:card" content="summary_large_image" /> */}
          {/* <meta property="twitter:domain" content="gstv.in" />
          <meta property="twitter:url" content={`${currentUrl}`} />
          <meta name="twitter:site" content="@GSTV_NEWS" />
          <meta name="twitter:title" content={`${metatitle}`} />
          <meta name="twitter:description" content={`${metadesc}`} />
          <meta name="twitter:image" content={`${newImageUrl}`} /> */}
      </Helmet>
)
}
export default HelmetTag;