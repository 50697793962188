// CategoryPage.js
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getNewsPreview } from '../services/allapis';
import { Helmet } from 'react-helmet';
import { UrlLists, DateComponent, truncateTitle } from './../utils/utils';
import NewsImagesVideos from './Newsimg';
import NewsShare from './NewsShare';
import NewsTags from './Newstag';
import NewsDescription from './Newsdesc';

const NewsPreview = () => {
  window.scrollTo(0, 0);
  const { slug: currentSlug } = useParams();
  const [data, setData] = useState(null);
  const [catdata, setDatas] = useState(null);
  const [NewsDataTxt, setDataTxt] = useState(null);
  

  useEffect(() => {
    // Check if the current slug is different from the previous one
    if (currentSlug) {
      const fetchDataFromApi = async () => {
        try {
          const result = await getNewsPreview(currentSlug);
          setData(result.newsDetail);
          setDatas(result.categories);
          setDataTxt(result.newsdefaulttxt);
          
          localStorage.setItem('newsData', JSON.stringify(result));
        } catch (error) {
          // Handle error
        }
      };

      fetchDataFromApi();
    } else {
      const newscachedData = localStorage.getItem('newsData');
      if (newscachedData) {
        const newsparsedData = JSON.parse(newscachedData);
        setData(newsparsedData.newsDetail);
        setDatas(newsparsedData.categories);
        setDataTxt(newsparsedData.newsdefaulttxt);
      }
    }
  }, [currentSlug]);
 
  const metatitle = data?.[0]?.metatitle;
  const metakeyword = data?.[0]?.metakeyword;
  const metadesc = data?.[0]?.metadesc;
  const FirstImages = data?.[0]?.featureImage;
  let newImageUrl;
  if (FirstImages) {
    const fileExtension = FirstImages.split('.').pop();
    const urlWithoutExtension = FirstImages.replace(/\.[^/.]+$/, "");
    newImageUrl = `${urlWithoutExtension}_small.${fileExtension}`;
  }
   
  const currentUrl = window.location.href;
  return (
    <>
      <Helmet>
          <title>{`${metatitle}`}</title>
          <meta name="keyword" content={`${metakeyword}`} />
          <meta name="description" content={`${metadesc}`} />
          <meta property="og:title" content={`${metatitle}`} />
          <meta property="og:description" content={`${metadesc}`} />
          <meta property="og:image" content={`${newImageUrl}`} />
          <meta property="og:image:secure_url" content={`${newImageUrl}`} />
          <meta property="og:image:type" content="image/webp" />
          <meta property="og:image:width" content="600" />
          <meta property="og:image:height" content="350" />
          <meta property="og:image:alt" content={`${metatitle}`} />
          <meta property="og:url" content={`${currentUrl}`} />
          <meta property="og:type" content="article" />
          <meta property="og:site_name" content="GSTV" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={`${metatitle}`} />
          <meta name="twitter:description" content={`${metadesc}`} />
          <meta name="twitter:image" content={`${newImageUrl}`} />
      </Helmet>
      
      {data && (
       <div className={`blogs-main-section inner custom-blog-details ${catdata?.slug}`}>
<div className="blogs-head-bar inner ">
    <span className="blog-category detail-page-heading"><Link to="/">Home</Link> / 
    {catdata ? (catdata.map((item, index) => ( 
      <span key={index}>
      <Link to={`/category/${item.slug}`}> {item.title}</Link>
      {index !== catdata.length - 1 && ' /'}
      </span>
    ))
    ) : (
      <></>
    )}  :  <i>{metatitle}</i></span>

</div>
<div className="detail-page-heading-h1">
    <h1>{data?.[0]?.title}</h1>
    {data?.[0]?.tags && <span>Tags: <NewsTags tags={data?.[0]?.tags} /></span>}
</div>

<div className="row blog-content">
<div className="col-lg-12 detail-page">
    <div className="blog-read-content"> 
        <div className="blog-featured-functions">
            <div className="reading-time-blog ">
                <img src="/assets/icons/clock.webp" /> Last Update : <DateComponent formattedDate={data?.[0]?.created_at} />
            </div>
            <NewsShare NewsLink={currentUrl} NewsTitle={data?.[0]?.title} />
            
        </div> 
    
    <NewsImagesVideos videoURL={data?.[0]?.videoURL} featureImage={data?.[0]?.featureImage} Title={data?.[0]?.title} imgCls="innerpage" />
    
    {data?.[0]?.description && (
      <NewsDescription description={data?.[0]?.description} />
    )}
    <h3 className="detail-page details-inner-footer whatsappgroupcls" dangerouslySetInnerHTML={{ __html: NewsDataTxt }} />
    
</div>
</div>



</div>

</div>
)}    </>
  );
};

export default NewsPreview;