import React, { useEffect, useState } from "react";
import { getpollresult } from "../services/allapis";

const Pollresult = () => {
  const [polldata, setpollData] = useState(null); 
  const [votedata, setvoteData] = useState(null);
  useEffect(() => {
  const fetchData = async () => {
    try {
      const result = await getpollresult();
      setpollData(result.pollresults);
      setvoteData(result.voteresults);
    } catch (error) {
      // Handle error
    } 
  };
   // Fetch data only if data is null
   if (!polldata) {
    fetchData();
  }
}, []);
  return (
    <>
      <div className="blogs-main-section-inner">
        <div className="blogs-head-bar first">
          <span className="blog-category">Poll Results</span>
        </div>
      
    

      {polldata && polldata.length > 0 ? (
        polldata.map((item, index) => {
          const pollVotes = votedata[item.id] || [];

            return (
              <div className="MCQ" key={index}>
                <h5 className="MCQ-Question custom-gujrati-font">{item.question}</h5>
                <div className="MCQ-options custom-gujrati-font poleresult">
                {pollVotes.map((pitem, pindex) => {
                  return (
                    <div key={pindex} className="all-options">
                      <label>{pitem.selectedAnswer}</label>&nbsp;:&nbsp;
                      <span>{pitem.answerCount ? pitem.answerCount : 0}</span>
                    </div>
                  )
                })}
            </div>

              </div>
            )
           
        })
      ) : (
        <></>
      )}
         
         </div>


    </>
  );
};

export default Pollresult;