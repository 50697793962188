import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getCategoryNews,submitImpressionData, getsatrangCategory } from '../services/allapis';
import { Helmet } from 'react-helmet';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ReadingTimeCalculator, truncateTitle, truncateDescription, UrlLists, BookmarkButton, DateComponent } from './../utils/utils';
import NewsImagesVideos from './Newsimg';
import NewsShare from './NewsShare';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faPlayCircle,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons';

const Category = () => {
  // window.scrollTo(0, 0);
  const { slug: currentSlug, subcatslug: currentSubcatSlug } = useParams();
  const [prevSlug, setPrevSlug] = useState(null);
  const [prevSubcatSlug, setPrevSubcatSlug] = useState(null);
  const [data, setData] = useState([]);
  const [dataCatTitle, setCatTitle] = useState(null);
  const [dataCatSlug, setCatSlug] = useState(null);
  const [datametaTitle, setMetatitle] = useState(null);
  const [datametadesc, setMetadesc] = useState(null);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [resultsat, setResultsat] = useState([]);

  const fetchData = async (pageNumber) => {
    try {
      const result = await getCategoryNews(currentSlug, currentSubcatSlug, pageNumber);
      const newData = result.news.data;
      if (newData.length > 0) {
        setData((prevData) => [...prevData, ...newData]);
        setPage(page + 1); // Increment the page for the next potential request
      } else {
        setHasMore(false); // No more data, set hasMore to false
      }

    } catch (error) {
      // Handle error
    } finally {
      setLoading(false);
    }
  };

  const fetchSatrangData = async () => {
    try {
      const resultset1 = await getsatrangCategory();
      setResultsat(resultset1);
    } catch (error) {
      // Handle error
    }
  };


  useEffect(() => {
    const fetchDataFromLocalStorage = () => {
      const catcachedData = localStorage.getItem('categoryData');
      if (catcachedData) {
        const parsedData = JSON.parse(catcachedData);
        setData(parsedData.data);
        setCatTitle(parsedData.catTitle);
        setCatSlug(parsedData.catSlug);
        setMetatitle(parsedData.metaTitle);
        setMetadesc(parsedData.metaDesc);
        setPrevSlug(parsedData.prevSlug);
        setPrevSubcatSlug(parsedData.prevSubcatSlug);
        setPage(parsedData.page);
        setHasMore(parsedData.hasMore);
        setLoading(false);
      }
    };

    fetchDataFromLocalStorage();
    fetchSatrangData();
  }, []);

  useEffect(() => {
    if ((currentSlug && currentSlug !== prevSlug) || currentSubcatSlug !== prevSubcatSlug) {
      const fetchDataFromApi = async () => {
        try {
          window.scrollTo(0, 0);
          const result = await getCategoryNews(currentSlug, currentSubcatSlug, 1);
          setData(result.news.data);
          setCatTitle(result.catTitle);
          setCatSlug(result.catSlug);
          setMetatitle(result.catMetatitle);
          setMetadesc(result.catMetadesc);
          setPrevSlug(currentSlug);
          setPrevSubcatSlug(currentSubcatSlug);
          setPage(1); 
          setHasMore(true);

          localStorage.setItem('categoryData', JSON.stringify({
            data: result.news.data,
            catTitle: result.catTitle,
            catSlug: result.catSlug,
            metaTitle: result.metaTitle,
            metaDesc: result.metaDesc,
            prevSlug: currentSlug,
            prevSubcatSlug: currentSubcatSlug,
            page: 1,
            hasMore: true
          }));
          
        } catch (error) {
          // Handle error
        } finally {
          setLoading(false);
        }
      };

      fetchDataFromApi();
    }
  }, [currentSlug, prevSlug, currentSubcatSlug, prevSubcatSlug, datametaTitle, datametadesc, dataCatTitle, dataCatSlug]);

  const handleFetchData = () => {
    if (hasMore) {
      fetchData(page + 1);
    }
  };
  useEffect(() => {
    // Function to handle initial logging on page load
    const logInitialImpressions = async () => {
      try {
        // Loop through each impression and log it
        const impressions = [
          { imgvalue: 'Riverfront' },
          { imgvalue: 'Aarogyaprad_Bhojan' },
          { imgvalue: 'Renewable_energy' },
          { imgvalue: 'Nmo_Lakshmi_Yojana' },
          { imgvalue: 'Cashless_sarvar' },
          { imgvalue: 'Nmo_Sarsavati_Yojana' },
        ].map(impression => ({ ...impression, currentPageUrl: window.location.href, click: 0 }));
        // Send the entire impressions array at once
        const result = await submitImpressionData(impressions);
      } catch (error) {
        console.error('Error in initial impression logging:', error);
      }
    };

    logInitialImpressions(); // Call the function immediately on page load
  }, []);
  const handleClick = async (imgvalue, redirectUrl) => {
    try {
      const impressions = [
        { imgvalue: imgvalue },
      ].map(impression => ({ ...impression, currentPageUrl: window.location.href, click: 1 }));
      // Await the result of the asynchronous function
      const result = await submitImpressionData(impressions);

      if (redirectUrl) {
        window.open(redirectUrl, '_blank');
      }
    } catch (error) {
      console.error('Error in handleClick:', error);
    }
  };
  const iconurl = UrlLists.catIcon;
  console.log(currentSlug +'---'+resultsat.length);
  return (
    <>
      {/* <div className="row blog-content adscls">
        <div className="col-md-4"><a href="#" onClick={(e) => { e.preventDefault(); handleClick('Riverfront', 'https://cmogujarat.gov.in/en'); }}><img src="/assets/graphics/img/01.jpg" className="gridimg custom-image lazyautosizes lazyloading" width="100%" /></a></div>
        <div className="col-md-4"><a href="#" onClick={(e) => { e.preventDefault(); handleClick('Aarogyaprad_Bhojan', 'https://cmogujarat.gov.in/en'); }}><img src="/assets/graphics/img/02.jpg" className="gridimg custom-image lazyautosizes lazyloading" width="100%" /></a></div>
        <div className="col-md-4"><a href="#" onClick={(e) => { e.preventDefault(); handleClick('Renewable_energy', 'https://cmogujarat.gov.in/en'); }}><img src="/assets/graphics/img/03.jpg" className="gridimg custom-image lazyautosizes lazyloading" width="100%" /></a></div>
      </div>
      <div className="row blog-content adsmobilecls">
        <div className="col-md-3"><a href="#" onClick={(e) => { e.preventDefault(); handleClick('Riverfront', 'https://cmogujarat.gov.in/en'); }}><img src="/assets/graphics/01.jpg" className="gridimg custom-image lazyautosizes lazyloading" width="100%" /></a></div>
      </div> */}
      <div className="blogs-main-section-inner">
        <div className="blogs-head-bar first ">
          <span className="blog-category">{dataCatTitle}</span>
        </div>
        
        <Helmet>
          <title>{`${datametaTitle}`}</title>
          <meta name="description" content={`${datametadesc}`} />
        </Helmet>
        { currentSlug==='gstv-satrang' && (currentSubcatSlug === undefined || currentSubcatSlug == '' || currentSubcatSlug == 'undefined') && resultsat.length > 0 ? (

            <div className="row blog-content">
              {resultsat.map((item) => (
                <div className="col-lg-3 col-6 custom-video-section" key={item.slug}>
                  <Link className="popupbtn" to={`/category/gstv-satrang/${item.slug}`}>
                    <div className="blog-read-content">
                      <div className="card">
                        <div className="img-wrappers">
                          <div className="videonewscls">
                            <img
                              className="gridimg custom-image lazyautosizes lazyloading"
                              src={`${iconurl}${item.icon}`}
                              alt={`${item.title}`}
                              height="200px"
                            />
                          </div>
                          <h4 className="video-title">{truncateTitle(item.title)}</h4>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          ):(
        <InfiniteScroll
          key={currentSlug}
          dataLength={data.length}
          next={handleFetchData}
          hasMore={hasMore}
          loader={<>{UrlLists.Loader}</>}
        >
          <div className="row blog-content">
            {data.map((item) => (
              item.catID.startsWith('9') ? (
                <div className="col-lg-3 col-6 custom-video-section">
                  <Link className='popupbtn' to={`/${currentSlug}${currentSubcatSlug ? `/${currentSubcatSlug}` : ''}/${item.slug}`}>
                    <div className="blog-read-content">
                      <div className="card">
                        <div className="img-wrapper">
                          <NewsImagesVideos videoURL={item.videoURL} featureImage={item.featureImage} Title={item.title} imgCls="videogridimg" />
                          <div className='play-icon'><FontAwesomeIcon icon={faPlayCircle} /></div>
                          <h4 className='video-title'>{item.title}</h4>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ) :  (
                <div className="col-lg-6">
                  <div className="blog-read-content custom-top-news">
                    <Link to={`/news/${currentSlug}${currentSubcatSlug ? `/${currentSubcatSlug}` : ''}/${item.slug}`}> 
                      <h4 className='custom-blog-title'>{truncateTitle(item.title, 15)}</h4>
                      <div className="hover-image">
                        <NewsImagesVideos videoURL={item.videoURL} featureImage={item.featureImage} Title={item.title} imgCls="gridimg" />
                      </div>
                      <p className="blog-excerpt" dangerouslySetInnerHTML={{ __html: truncateDescription(item.description, 25) }} />
                    </Link>
                    <span className="last-update-blog">Last Update : <DateComponent formattedDate={item.created_at} /></span>
                    <div className="blog-featured-functions">
                      <div className="reading-time-blog">
                        <ReadingTimeCalculator content={item.description} wordsPerMinute={100} />
                      </div>
                      <NewsShare NewsLink={`${UrlLists.domainURL}/news/${currentSlug}${currentSubcatSlug ? `/${currentSubcatSlug}` : ''}/${item.slug}`} NewsTitle={item.title} />
                    </div>
                  </div>
                </div>
              )
            ))}
          </div>
        </InfiniteScroll>
          )}
      </div>
      <div className="row pt-3 blog-content adscls">
        <div className="col-md-3"><a href="#" onClick={(e) => { e.preventDefault(); handleClick('Nmo_Lakshmi_Yojana', 'https://cmogujarat.gov.in/en'); }}><img src="/assets/graphics/img/04.jpg" className="gridimg custom-image lazyautosizes lazyloading" width="100%" /></a></div>
        <div className="col-md-3"><a href="#" onClick={(e) => { e.preventDefault(); handleClick('Cashless_sarvar', 'https://cmogujarat.gov.in/en'); }}><img src="/assets/graphics/img/05.jpg" className="gridimg custom-image lazyautosizes lazyloading" width="100%" /></a></div>
        <div className="col-md-3"><a href="#" onClick={(e) => { e.preventDefault(); handleClick('Nmo_Sarsavati_Yojana', 'https://cmogujarat.gov.in/en'); }}><img src="/assets/graphics/img/06.jpg" className="gridimg custom-image lazyautosizes lazyloading" width="100%" /></a></div>
        <div className="col-md-3"><a href="#" onClick={(e) => { e.preventDefault(); handleClick('Smart_and_Digital', 'https://cmogujarat.gov.in/en'); }}><img src="/assets/graphics/img/07.jpg" className="gridimg custom-image lazyautosizes lazyloading" width="100%" /></a></div>
      </div>
      <div className="row blog-content adsmobilecls">
        <div className="col"><a href="#" onClick={(e) => { e.preventDefault(); handleClick('Nmo_Lakshmi_Yojana', 'https://cmogujarat.gov.in/en'); }}><img src="/assets/graphics/img/04.jpg" className="gridimg custom-image lazyautosizes lazyloading" width="100%" /></a></div>
        <div className="col"><a href="#" onClick={(e) => { e.preventDefault(); handleClick('Cashless_sarvar', 'https://cmogujarat.gov.in/en'); }}><img src="/assets/graphics/img/05.jpg" className="gridimg custom-image lazyautosizes lazyloading" width="100%" /></a></div>
        <div className="col"><a href="#" onClick={(e) => { e.preventDefault(); handleClick('Nmo_Sarsavati_Yojana', 'https://cmogujarat.gov.in/en'); }}><img src="/assets/graphics/img/06.jpg" className="gridimg custom-image lazyautosizes lazyloading" width="100%" /></a></div>
      </div>
    </>
  );
};

export default Category;
