import React, { useState } from "react";
import { submitGeneralData } from "../services/allapis";

const Election = () => {
  const initialFormData = {
    name: "",
    address: "",
    age: "",
    education: "",
    phone: "",
    whatsapp: "",
    email: "",
    elec_type: "",
    bjp: "",
    con: "",
    any: "",
    gujarat_bjp: "",
    gujarat_con: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = "Name is required.";
      isValid = false;
    }

    if (!formData.email.trim()) {
      newErrors.email = "Email address is required.";
      isValid = false;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email.trim())) {
      newErrors.email = "Invalid email format.";
      isValid = false;
    }

    if (!formData.phone.trim()) {
      newErrors.phone = "Mobile No is required.";
      isValid = false;
    } else if (!/^[0-9]*$/.test(formData.phone.trim())) {
      newErrors.phone = "Invalid Mobile No.";
      isValid = false;
    }
    if (!/^[0-9]*$/.test(formData.whatsapp.trim())) {
      newErrors.whatsapp = "Invalid Whatsapp No.";
      isValid = false;
    }
    // Check that at least one of bjp, con, or any is filled
    if (!formData.bjp.trim() && !formData.con.trim() && !formData.any.trim()) {
      newErrors.general = "At least one prediction is required.";
      isValid = false;
    }

     // Check that at least one of gujarat_bjp or gujarat_con is filled
     if (!formData.gujarat_bjp.trim() && !formData.gujarat_con.trim()) {
      newErrors.gujarat = "At least one prediction is required.";
      isValid = false;
    }

    // Check that one of the radio buttons (elec_type) is selected
    if (!formData.elec_type) {
      newErrors.elec_type = "Please select one.";
      isValid = false;
    }


    setErrors(newErrors);
    return isValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
     
      setFormData({
        ...formData,
        [name]: value,
      });
     
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const result = await submitGeneralData(formData);
        setFormData(initialFormData);
        window.scrollTo(0, 0);
        setErrors({});
        if(result.success==="error"){
          setErrorMessage(result.msg);
        }
        else {
          setSuccessMessage(result.msg);
        }
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
        setTimeout(() => {
          setSuccessMessage("");
        }, 3000);
      } catch (error) {
        console.error("Error posting data:", error);
      }
    }
  };


  return (
    <>
      <div className="blogs-main-sectioninner">
        {/* <div className="blogs-head-bar first">
          <span className="blog-category">ચૂંટણી 2024 માટે આગાહી</span>
        </div> */}
        {successMessage && <div className="success-message succmsg">{successMessage}</div>}
        {errorMessage && <div className="success-message errormsg">{errorMessage}</div>}
        <div class="banner">
        <h2>Loksabha Election 2024 Prediction</h2>
        <h3 className="pt-4">Registration has been closed</h3>
        <p className="blink">Results will be announced on 5th June</p>
    </div>
        {/*<form onSubmit={handleSubmit}>
          <div className="row blog-content pt-3">
            <div className="col-lg-12 detail-page custom-content-page election-form">
              <div className="">
                <div className="row">
                  <div className="col-lg-6">
                    <label className="required">જ્યોતિષીનું નામઃ </label>
                    <input className="frmelecls" type="text" name="name" value={formData.name} onChange={handleChange} />
                    {errors.name && <span className="error">{errors.name}</span>}
                  </div>
                  <div className="col-lg-6">
                    <label>સરનામુઃ</label>
                    <input className="frmelecls" type="text" name="address" value={formData.address} onChange={handleChange} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <label>ઉંમરઃ</label>
                    <input className="frmelecls" type="text" name="age" value={formData.age} onChange={handleChange} />
                  </div>
                  <div className="col-lg-6">
                    <label>શૈક્ષણિક લાયકાતઃ</label>
                    <input className="frmelecls" type="text" name="education" value={formData.education} onChange={handleChange} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <label className="required">મોબાઇલ નંબરઃ</label>
                    <input className="frmelecls" type="text" name="phone" maxLength="10" value={formData.phone} onChange={handleChange} />
                    {errors.phone && <span className="error">{errors.phone}</span>}
                  </div>
                  <div className="col-lg-6">
                    <label>વોટ્સએપ નંબરઃ</label>
                    <input className="frmelecls" type="text" name="whatsapp" maxLength="10"  value={formData.whatsapp} onChange={handleChange} />
                    {errors.whatsapp && <span className="error">{errors.whatsapp}</span>}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <label className="required">ઈ - મેઈલ એડ્રેસ:</label>
                    <input className="frmelecls" type="email" name="email" value={formData.email} onChange={handleChange} />
                    {errors.email && <span className="error">{errors.email}</span>}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <label className="required">દેશમાં (આગાહી)</label><br />
                    ભાજપઃ <input type="text"  name="bjp" value={formData.bjp} onChange={handleChange} max="543" maxLength={3}  className="underline-input" /> / 543<br />
                    કોંગ્રેસ: <input type="text"  name="con" value={formData.con} onChange={handleChange} max="543" maxLength={3} className="underline-input" /> / 543<br />
                    અન્યઃ <input type="text"  name="any" value={formData.any} onChange={handleChange} max="543" maxLength={3} className="underline-input" /> / 543<br />
                    {errors.general && <span className="error">{errors.general}</span>}
                  </div>
                  <div className="col-lg-6">
                    <label className="required">ગુજરાતમાં (આગાહી)</label><br />
                    ભાજપઃ <input type="text"  name="gujarat_bjp" value={formData.gujarat_bjp} max="26" maxLength={2} onChange={handleChange} className="underline-input" /> / 26<br />
                    કોંગ્રેસ: <input type="text"  name="gujarat_con" value={formData.gujarat_con} max="26" maxLength={2} onChange={handleChange} className="underline-input" /> / 26<br />
                    {errors.gujarat && <span className="error">{errors.gujarat}</span>}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <label className="required">કોની સરકાર બનશે?</label><br />
                    <input  type="radio"  name="elec_type"  value="NDA"  onChange={handleChange}  checked={formData.elec_type === "NDA"}/>{" "}NDA ગઠબંધન &nbsp;&nbsp;
                    <input  type="radio"  name="elec_type"  value="INDIA"  onChange={handleChange}  checked={formData.elec_type === "INDIA"}/>{" "}INDIA ગંઠબંધન<br/>
                    {errors.elec_type && <span className="error">{errors.elec_type}</span>}
                  </div>
                </div>
                <div className="row pb-4">
                  <div className="text-left">
                    <button type="submit" className="btnCls">submit</button>
                  </div>
                </div>
               
                <div className="row">
                  <div className="col-lg-12">
                    <h4>સ્પર્ધામાં ભાગ લેવાના નિયમો:</h4>
                    <p>
                      1) દરેક જ્યોતિષીએ ઑનલાઇન ફોર્મ ભરવાનું રહેશે.<br />
                      2) માત્ર એક જ વખત ફોર્મ ભરી શકાશે. જો કોઈ જ્યોતિષી બે વખત ફોર્મ ભરશે તો પહેલી જ એન્ટ્રી માન્ય ગણાશે.<br />
                      3) વાદ-વિવાદના કિસ્સામાં જીએસટીવીના મેનેજમેન્ટ દ્વારા લેવામાં આવનારો નિર્ણય આખરી રહેશે.</p>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
  </form>*/}
      </div>
    </>
  );
};

export default Election;
