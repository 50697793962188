import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { getWebstoryPreview } from "../services/allapis";


const WebstoryPreview = () => {
  const { slug } = useParams();
  const [data, setData] = useState(null);
  const [datamore, setDatamore] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!data) {
      const fetchDataFromApi = async () => {
        try {
          const result = await getWebstoryPreview(slug);
          if (result.webstory && Array.isArray(result.webstory)) {
            setData(result.webstory);
            setDatamore(result.webstorymore);
          } else {
            setError("Invalid data received");
          }
        } catch (error) {
          setError("Error fetching data");
        }
      };
      fetchDataFromApi();
    }
    const script1 = document.createElement("script");
    script1.src = "https://cdn.ampproject.org/v0.js";
    script1.async = true;
    document.body.appendChild(script1);

    // Create and append the second script element
    const script2 = document.createElement("script");
    script2.src = "https://cdn.ampproject.org/v0/amp-story-1.0.js";
    script2.async = true;
    document.body.appendChild(script2);

    // Remove the scripts when the component is unmounted
    return () => {
      document.body.removeChild(script1);
      document.body.removeChild(script2);
    };
  }, [data, datamore, slug]);

  

  if (!data) {
    return <div>Loading...</div>;
  }

  const metatitle = data?.[0]?.metatitle;
  const metadesc = data?.[0]?.metadesc;
  const storydata = JSON.parse(data?.[0]?.Story_data || "[]");

  return (
    <>
      <Helmet>
        <title>{`${metatitle}`}</title>
        <meta name="description" content={`${metadesc}`} />
        {/* Add other meta tags */}
      </Helmet>

      <div className="webstory-fullscreen">
        <div className="row custom-web-details">
          <div className="col-lg-4 col-md-6 col-sm-12 custom-image-content">
            <div className="carousal-items">
              <amp-story standalone>
                {storydata.map((image, index) => (
                  <amp-story-page
                    auto-advance-after="9s"
                    key={index}
                    className="webstory-details-content"
                  >
                    <amp-story-grid-layer template="fill">
                      <amp-img  
                        animate-in="fly-in-top"
                        animate-in-duration="1s"
                        src={image.webimage.replace(".webp", "_medium.webp")}
                        layout="responsive"
                        alt={image.webtitles}
                        
                      ></amp-img>
                    </amp-story-grid-layer>
                    
                    {image.webtitles && (
                      <amp-story-grid-layer template="vertical">
                        <p
                          animate-in="fly-in-bottom"
                          animate-in-duration="4s"
                          className="web-details-title"
                        >
                          {image.webtitles}
                          <div className="title-bg"></div>
                        </p>
                        
                      </amp-story-grid-layer>
                    )}
                  </amp-story-page>
                ))}
                
                <amp-story-page auto-advance-after="9s">
                  <amp-story-layer>
                  {datamore.map((item, index) => {
                      return <div key={index} className="blockgrid web-story-card custom-last-card">
                          <Link className='popupbtn' to={`/web-stories/${item.slug.replace(/,/g, '/')}`}>
                          <div className="custom-webstory-image"><img src={JSON.parse(item.Story_data)[0]['webimage'].replace('.webp', '_small.webp')} className='blog-featured-img img-fluid' /></div>
                          <div className="webstory-title-content custom-gujrati-font"><h4>{item.title}</h4></div>
                          </Link>
                          </div>  
                  })}
                  </amp-story-layer>
                </amp-story-page>
                
              </amp-story>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WebstoryPreview;