import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DateComponent, ReadingTimeCalculator, UrlLists, truncateTitle, truncateDescription } from './../utils/utils';
import NewsImagesVideos from './Newsimg';
import NewsShare from './NewsShare';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { getHomeCategorys } from '../services/allapis';

const HomeCategorynews = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchDataFromApi = async () => {
      try {
        const result = await getHomeCategorys();
        setData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (!data) {
      fetchDataFromApi();
    }
  }, [data]);

  const handleClick = (category, url) => {
    window.open(url, '_blank');
  };

  const renderAds = () => (
    <>
      <div className="row blog-content mt-3 adscls">
        <div className="col-md-3">
          <a href="#" onClick={(e) => { e.preventDefault(); handleClick('Riverfront', 'https://cmogujarat.gov.in/en'); }}>
            <img src="/assets/graphics/img/01.jpg" className="gridimg custom-image lazyautosizes lazyloading" width="100%" />
          </a>
        </div>
        <div className="col-md-3">
          <a href="#" onClick={(e) => { e.preventDefault(); handleClick('Aarogyaprad_Bhojan', 'https://cmogujarat.gov.in/en'); }}>
            <img src="/assets/graphics/img/02.jpg" className="gridimg custom-image lazyautosizes lazyloading" width="100%" />
          </a>
        </div>
        <div className="col-md-3">
          <a href="#" onClick={(e) => { e.preventDefault(); handleClick('Renewable_energy', 'https://cmogujarat.gov.in/en'); }}>
            <img src="/assets/graphics/img/03.jpg" className="gridimg custom-image lazyautosizes lazyloading" width="100%" />
          </a>
        </div>
        <div className="col-md-3">
          <a href="#" onClick={(e) => { e.preventDefault(); handleClick('Nmo_Lakshmi_Yojana', 'https://cmogujarat.gov.in/en'); }}>
            <img src="/assets/graphics/img/04.jpg" className="gridimg custom-image lazyautosizes lazyloading" width="100%" />
          </a>
        </div>
      </div>
      <div className="row blog-content adsmobilecls">
      <div className="col">
          <a href="#" onClick={(e) => { e.preventDefault(); handleClick('Riverfront', 'https://cmogujarat.gov.in/en'); }}>
            <img src="/assets/graphics/img/01.jpg" className="gridimg custom-image lazyautosizes lazyloading" width="100%" />
          </a>
        </div>
        <div className="col">
          <a href="#" onClick={(e) => { e.preventDefault(); handleClick('Aarogyaprad_Bhojan', 'https://cmogujarat.gov.in/en'); }}>
            <img src="/assets/graphics/img/02.jpg" className="gridimg custom-image lazyautosizes lazyloading" width="100%" />
          </a>
        </div>
        <div className="col">
          <a href="#" onClick={(e) => { e.preventDefault(); handleClick('Renewable_energy', 'https://cmogujarat.gov.in/en'); }}>
            <img src="/assets/graphics/img/03.jpg" className="gridimg custom-image lazyautosizes lazyloading" width="100%" />
          </a>
        </div>
      </div>
    </>
  );

  const renderNews = (category,index) => (
    <>
    <div className="blogs-main-section" key={category.catid}>
      <div className="blogs-head-bar first">
        <Link to={`/category/${category.catslug}`}>
          <span className="blog-category">{category.categorytitle}</span>
        </Link>
        <Link to={`/category/${category.catslug}`} className='custom-link-btn'>
          <FontAwesomeIcon icon={faChevronRight} />
        </Link>
      </div>
      <div className="row blog-content">
        {category.newsData && category.newsData.map((newsItem, index) => (
          <div className="col-lg-6" key={newsItem.id}>
            <div className="blog-read-content custom-top-news">
              <Link to={`/news/${newsItem.category_slugs.replace(/,/g, '/')}/${newsItem.slug}`}>
                <h4 className='custom-blog-title'>{truncateTitle(newsItem.title, 15)}</h4>
                <div className="hover-image">
                  <NewsImagesVideos videoURL={newsItem.videoURL} featureImage={newsItem.featureImage} Title={newsItem.title} imgCls="gridimg" />
                </div>
                <p className="blog-excerpt" dangerouslySetInnerHTML={{ __html: truncateDescription(newsItem.description, 25) }} />
              </Link>
              <span className="last-update-blog">Last Update : <DateComponent formattedDate={newsItem.created_at} /></span>
              <div className="blog-featured-functions">
                <div className="reading-time-blog">
                  <ReadingTimeCalculator content={newsItem.description} wordsPerMinute={100} />
                </div>
                <NewsShare NewsLink={`${UrlLists.domainURL}/news/${newsItem.category_slugs.replace(/,/g, '/')}/${newsItem.slug}`} NewsTitle={newsItem.title} />
              </div>
            </div>
          </div>
        ))}
      </div>
     
     
    </div>
   
    {index == 3 && renderAds()}</>
  );

  const homecategoryData = data;

  return (
    <>
      {homecategoryData && homecategoryData.length > 0 ? (
        homecategoryData.map((category, index) => renderNews(category, index))
      ) : (
        <>{UrlLists.Loader}</>
      )}
    </>
  );
};

export default HomeCategorynews;
