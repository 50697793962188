import React, { useState } from "react";
import { submitFeedbackData } from "../../services/allapis";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faMobileScreenButton,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
const Feedback = () => {
  const initialFormData = {
    name: "",
    email: "",
    phone: "",
    comment: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    // Validation for the 'name' field
    if (!formData.name.trim()) {
      newErrors.name = "Name is required.";
      isValid = false;
    }

    // Validation for the 'email' field
    if (!formData.email.trim()) {
      newErrors.email = "Email is required.";
      isValid = false;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email.trim())) {
      newErrors.email = "Invalid email format.";
      isValid = false;
    }

    // Validation for the 'phone' field
    if (!formData.phone.trim()) {
      newErrors.phone = "Phone is required.";
      isValid = false;
    } else if (!/^[0-9]*$/.test(formData.phone.trim())) {
      newErrors.phone = "Invalid phone number.";
      isValid = false;
    }

    if (!formData.comment.trim()) {
      newErrors.comment = "Comment is required.";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };
  const handleChange = (e) => {
    if (e.target.name === "phone" && e.target.value.length > 15) {
      return;
    }
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
      [e.target.email]: e.target.value,
      [e.target.phone]: e.target.value,
      [e.target.comment]: e.target.value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const fetchDataFromApi = async () => {
        try {
          const result = await submitFeedbackData(formData);
          setFormData(initialFormData);
          setErrors({});
          setSuccessMessage("Thank you for sharing this with us");
          setTimeout(() => {
            setSuccessMessage("");
          }, 3000);
        } catch (error) {
          console.error("Error posting data:", error);
        }
      };
      fetchDataFromApi();
    }
  };

  return (
    <div className="enquiry-form">
      <form onSubmit={handleSubmit}>
        <h6 className="heading-text">Feedback Form</h6>
        <div className="form-inputs ">
          <label>Additional feedback</label>
          <div className="inner-inputs">
            <textarea
              name="comment"
              placeholder="please type comment here..."
              value={formData.comment}
              onChange={handleChange}
              className={errors.comment && "error-input"}
            ></textarea>
          </div>
          {errors.comment && (
            <span className="error-message">{errors.comment}</span>
          )}
        </div>
        <div className="form-inputs">
          <label>Name</label>
          <div className="inner-inputs custom-inputs">
            <input
              type="text"
              name="name"
              placeholder="Enter the name"
              value={formData.name}
              onChange={handleChange}
              className={errors.name && "error-input"}
            />
            <FontAwesomeIcon icon={faUser} />
          </div>
          {errors.name && <span className="error-message">{errors.name}</span>}
        </div>
        <div className="form-inputs">
          <label>Email</label>
          <div className="inner-inputs custom-inputs">
            <input
              type="email"
              name="email"
              placeholder="Enter the email address"
              value={formData.email}
              onChange={handleChange}
              className={errors.email && "error-input"}
            />
            <FontAwesomeIcon icon={faEnvelope} />
          </div>
          {errors.email && (
            <span className="error-message">{errors.email}</span>
          )}
        </div>
        <div className="form-inputs">
          <label>Phone Number</label>
          <div className="inner-inputs custom-inputs">
            <input
              type="tel"
              name="phone"
              placeholder="Enter the phone number"
              value={formData.phone}
              pattern="[0-9]*"
              maxLength="10"
              onChange={handleChange}
              className={errors.phone && "error-input"}
            />
            <FontAwesomeIcon icon={faMobileScreenButton} />
          </div>
          {errors.phone && (
            <span className="error-message">{errors.phone}</span>
          )}
        </div>
        
        <div className="text-center">
          <button type="submit">submit feedback</button>
        </div>
        {successMessage && (
          <div className="success-message">{successMessage}</div>
        )}
      </form>
    </div>
  );
};

export default Feedback;
