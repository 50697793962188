import React, { useEffect } from 'react';

const AdsScript = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.defer = true;
    script.async = true;
    script.src = `${window.location.protocol}//a.vdo.ai/core/v-gstv-in-v0/vdo.ai.js`;
    document.head.appendChild(script);

    return () => {
      // Clean up function to remove the script when the component is unmounted
      document.head.removeChild(script);
    };
  }, []); // Empty dependency array ensures that this effect runs only once after the component mounts

  return (
    <div id="v-gstv-in-v0"></div>
  );
};

export default AdsScript;
