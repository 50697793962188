import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getNewsRelated, submitImpressionData } from '../services/allapis';
import { Helmet } from 'react-helmet';
import { DateComponent, truncateTitle } from './../utils/utils';
import NewsImagesVideos from './Newsimg';
import NewsShare from './NewsShare';
import NewsTags from './Newstag';
import NewsDescription from './Newsdesc';
import HelmetTag from './layouts/HelmetTag';

const News = () => {
  window.scrollTo(0, 0);
  const { slug: currentSlug, category: currentcatSlug, subcategory: currentSubcatSlug } = useParams();
  const [data, setData] = useState(null);
  const [catdata, setDatas] = useState(null);
  const [NewsDataTxt, setDataTxt] = useState(null);
  const [relateddata, setDatarelated] = useState(null);
  const divRef = useRef(null);

  const fetchDataFromApi = async () => {
    try {
      const result = await getNewsRelated(currentSlug);
      setData(result.newsDetail);
      setDatas(result.categories);
      setDataTxt(result.newsdefaulttxt);
      setDatarelated(result.relatednews);
      localStorage.setItem('newsData', JSON.stringify(result));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (currentSlug) {
      fetchDataFromApi();
    } else {
      const newscachedData = localStorage.getItem('newsData');
      if (newscachedData) {
        const newsparsedData = JSON.parse(newscachedData);
        setData(newsparsedData.newsDetail);
        setDatas(newsparsedData.categories);
        setDataTxt(newsparsedData.newsdefaulttxt);
        setDatarelated(newsparsedData.relatednews);
      }
    }
  }, [currentSlug, currentcatSlug, currentSubcatSlug]);

  const metatitle = data?.metatitle;
  const metakeyword = data?.metakeyword;
  const metadesc = data?.metadesc;
  const FirstImages = data?.featureImage;
  let newImageUrl;
  let fileExtension;
  if (FirstImages) {
    fileExtension = FirstImages.split('.').pop();
    const urlWithoutExtension = FirstImages.replace(/\.[^/.]+$/, '');
    newImageUrl = `${urlWithoutExtension}_medium.${fileExtension}`;
  }

  const getFileTypeMetaTag = (fileExtension) => {
    if (fileExtension === 'png') {
      return <meta property="og:image:type" content="image/png" />;
    } else if (fileExtension === 'jpeg' || fileExtension === 'jpg') {
      return <meta property="og:image:type" content="image/jpeg" />;
    } else {
      return null;
    }
  };

  let fileTypeMetaTag = getFileTypeMetaTag(fileExtension);
  const currentUrl = window.location.href;

  useEffect(() => {
    // Function to handle initial logging on page load
    const logInitialImpressions = async () => {
      try {
        const impressions = [
          { imgvalue: 'MSME_Ekmo' },
          { imgvalue: 'U_S_Dollar' },
          { imgvalue: 'Milk_utpadan' },
          { imgvalue: 'Prakrutik_kheti' },
          { imgvalue: 'van_bandhu_kalyan_yojana' },
          { imgvalue: 'mata_pita_yojana' },
        ].map(impression => ({ ...impression, currentPageUrl: window.location.href, click: 0 }));
  
        // Send the entire impressions array at once
        const result = await submitImpressionData(impressions);
      } catch (error) {
        console.error('Error in initial impression logging:', error);
      }
    };

    logInitialImpressions(); // Call the function immediately on page load
  }, []);

  const handleClick = async (imgvalue, redirectUrl) => {
    try {
      const impressions = [
        { imgvalue: imgvalue },
      ].map(impression => ({ ...impression, currentPageUrl: window.location.href, click: 1 }));
      // Await the result of the asynchronous function
      const result = await submitImpressionData(impressions);

      if (redirectUrl) {
        window.open(redirectUrl, '_blank');
      }
    } catch (error) {
      console.error('Error in handleClick:', error);
    }
  };

  useEffect(() => {
    if (!divRef.current) return;
    const domain = (window.location !== window.parent.location) ? document.referrer : document.location.href;
    const scpt = document.createElement("script");
    const GetAttribute = "afpftpPixel_" + (Math.floor((Math.random() * 500) + 1)) + "_" + Date.now();
    scpt.src = "//adgebra.co.in/afpf/GetAfpftpJs?parentAttribute=" + GetAttribute;
    scpt.id = GetAttribute;
    scpt.setAttribute("data-pubid", "361");
    scpt.setAttribute("data-slotId", "1");
    scpt.setAttribute("data-templateId", "7253");
    scpt.setAttribute("data-accessMode", "0");
    scpt.setAttribute("data-domain", "gstv.in");
    scpt.setAttribute("data-divId", "div_20240619161225");
    divRef.current.appendChild(scpt);
  }, [data]);

  return (
    <>
      <HelmetTag image={newImageUrl} />
      <Helmet>
        <title>{`${metatitle}`}</title>
        <meta name="keyword" content={`${metakeyword}`} />
        <meta name="description" content={`${metadesc}`} />
        <meta property="og:url" content={`${currentUrl}`} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`${metatitle}`} />
        <meta property="og:description" content={`${metadesc}`} />
        <meta property="og:image" content={`${newImageUrl}`} />
        {fileTypeMetaTag}
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="gstv.in" />
        <meta property="twitter:url" content={`${currentUrl}`} />
        <meta name="twitter:site" content="@GSTV_NEWS" />
        <meta name="twitter:title" content={`${metatitle}`} />
        <meta name="twitter:description" content={`${metadesc}`} />
        <meta name="twitter:image" content={`${newImageUrl}`} />
      </Helmet>

      {data && (
        <div className={`blogs-main-section inner custom-blog-details ${catdata?.slug}`}>
          <div className="blogs-head-bar inner ">
            <span className="blog-category detail-page-heading">
              <Link to="/">Home</Link> / 
              {catdata ? (catdata.map((item, index) => ( 
                <span key={index}>
                  <Link to={`/category/${item.slug}`}> {item.title}</Link>
                  {index !== catdata.length - 1 && ' /'}
                </span>
              ))) : (<></>)}
              : <i>{metatitle}</i>
            </span>
          </div>
          <div className="detail-page-heading-h1">
            <h1>{data?.title}</h1>
            {data?.tags && <span>Tags: <NewsTags tags={data?.tags} /></span>}
          </div>

          <div className="row blog-content">
            <div className="col-lg-12 detail-page">
              <div className="blog-read-content"> 
                <div className="blog-featured-functions">
                  <div className="reading-time-blog">
                    <img src="/assets/icons/clock.webp" alt="" /> Last Update : <DateComponent formattedDate={data?.created_at} />
                  </div>
                  <NewsShare NewsLink={currentUrl} NewsTitle={data?.title} />
                </div> 
              
                <NewsImagesVideos videoURL={data?.videoURL} featureImage={data?.featureImage} Title={data?.title} imgCls="innerpage" />
                
                {data?.description && (
                  <NewsDescription description={data?.description} />
                )}

                {/* <div className="row blog-content mt-3 adscls">
                  <div className="col-md-4"><a href="#" onClick={(e) => { e.preventDefault(); handleClick('MSME_Ekmo', 'https://cmogujarat.gov.in/en'); }}><img src="/assets/graphics/img/09.jpg" className="gridimg custom-image lazyautosizes lazyloading" width="100%" /></a></div>
                  <div className="col-md-4"><a href="#" onClick={(e) => { e.preventDefault(); handleClick('U_S_Dollar', 'https://cmogujarat.gov.in/en'); }}><img src="/assets/graphics/img/10.jpg" className="gridimg custom-image lazyautosizes lazyloading" width="100%" /></a></div>
                  <div className="col-md-4"><a href="#" onClick={(e) => { e.preventDefault(); handleClick('Milk_utpadan', 'https://cmogujarat.gov.in/en'); }}><img src="/assets/graphics/img/11.jpg" className="gridimg custom-image lazyautosizes lazyloading" width="100%" /></a></div>
                </div>
                <div className="row blog-content adsmobilecls">
                  <div className="col-md-3"><a href="#" onClick={(e) => { e.preventDefault(); handleClick('MSME_Ekmo', 'https://cmogujarat.gov.in/en'); }}><img src="/assets/graphics/img/09.jpg" className="gridimg custom-image lazyautosizes lazyloading" width="100%" /></a></div>
                </div> */}

                <h3 className="detail-page details-inner-footer whatsappgroupcls" dangerouslySetInnerHTML={{ __html: NewsDataTxt }} />
                <div id="div_20240619161225" className='col-12 newsadsbottom' ref={divRef}></div>
              </div>
            </div>
          </div>
        </div>
      )}
      
      {relateddata && relateddata.length > 0 ? (
        <div className="blogs-main-section">
          <div className="blogs-head-bar first">
            <span className="blog-category">Related News</span>
          </div>
          <div className="row blog-content related-blog-content">
            {relateddata.map((relatedItem) => (
              <div className="col-lg-4" key={relatedItem.slug}>
                <div className="blog-read-content related-blog">  
                  <Link to={`/news/${currentcatSlug}${currentSubcatSlug ? `/${currentSubcatSlug}` : ''}/${relatedItem.slug}`}>
                    <NewsImagesVideos videoURL={relatedItem.videoURL} featureImage={relatedItem.featureImage} Title={relatedItem.title} imgCls="gridimg" />
                    <h4 className="related-blog-title">{truncateTitle(relatedItem.title, 100)}</h4>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className="row blog-content mt-3 adscls">
        <div className="col-md-3"><a href="#" onClick={(e) => { e.preventDefault(); handleClick('Milk_utpadan', 'https://cmogujarat.gov.in/en'); }}><img src="/assets/graphics/img/11.jpg" className="gridimg custom-image lazyautosizes lazyloading" width="100%" /></a></div>
        <div className="col-md-3"><a href="#" onClick={(e) => { e.preventDefault(); handleClick('Prakrutik_kheti', 'https://cmogujarat.gov.in/en'); }}><img src="/assets/graphics/img/12.jpg" className="gridimg custom-image lazyautosizes lazyloading" width="100%" /></a></div>
        <div className="col-md-3"><a href="#" onClick={(e) => { e.preventDefault(); handleClick('van_bandhu_kalyan_yojana', 'https://cmogujarat.gov.in/en'); }}><img src="/assets/graphics/img/13.jpg" className="gridimg custom-image lazyautosizes lazyloading" width="100%" /></a></div>
        <div className="col-md-3"><a href="#" onClick={(e) => { e.preventDefault(); handleClick('mata_pita_yojana', 'https://cmogujarat.gov.in/en'); }}><img src="/assets/graphics/img/14.jpg" className="gridimg custom-image lazyautosizes lazyloading" width="100%" /></a></div>
      </div>
      <div className="row blog-content adsmobilecls">
        <div className="col"><a href="#" onClick={(e) => { e.preventDefault(); handleClick('Prakrutik_kheti', 'https://cmogujarat.gov.in/en'); }}><img src="/assets/graphics/img/12.jpg" className="gridimg custom-image lazyautosizes lazyloading" width="100%" /></a></div>
        <div className="col"><a href="#" onClick={(e) => { e.preventDefault(); handleClick('van_bandhu_kalyan_yojana', 'https://cmogujarat.gov.in/en'); }}><img src="/assets/graphics/img/13.jpg" className="gridimg custom-image lazyautosizes lazyloading" width="100%" /></a></div>
        <div className="col"><a href="#" onClick={(e) => { e.preventDefault(); handleClick('mata_pita_yojana', 'https://cmogujarat.gov.in/en'); }}><img src="/assets/graphics/img/14.jpg" className="gridimg custom-image lazyautosizes lazyloading" width="100%" /></a></div>
      </div>
    </>
  );
};

export default News;
