import React from 'react';
import { Link } from 'react-router-dom';

const Address = () => {
  return (
    <div className="address">
  <h6><ul className="custom-address-list"><li><Link to="contact-us">Contact Us </Link></li><span>|</span> <li> <Link to="cookie-policy"> Cookie Policy</Link></li><span>|</span>
  <li><Link to="privacy-policy"> Privacy Policy</Link></li></ul></h6>
    <h6 className="custom-text"><div> Designed & Developed by  <span className="highlight-text">GSTV</span></div><br/>© Copyright 2024 | GSTV. All rights reserved.</h6>
</div>
  );
};

export default Address;