import React, { useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { truncateTitle, UrlLists } from './../utils/utils';
import NewsImagesVideos from './Newsimg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlayCircle,
  faChevronRight
} from "@fortawesome/free-solid-svg-icons";
import { getSatrangSlider } from '../services/allapis';




const MyCarousel = () => {
    const [videodata, setData] = useState(null);
    useEffect(() => {
      const fetchDataFromApi = async () => {
        try {
          const result = await getSatrangSlider();
          console.log(result);
          setData(result);
        } catch (error) {
          // Handle error
          console.error("Error fetching data:", error);
        }
      };
  
      // Fetch data only if data is null
      if (!videodata) {
        fetchDataFromApi();
      }
    }, [videodata]);
  const data = videodata;
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      partialVisibilityGutter: 43, // Adjust this value as needed
      transitionDuration: 300,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      partialVisibilityGutter: 30, // Adjust this value as needed
      transitionDuration: 300,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
      partialVisibilityGutter: 5, // Adjust this value as needed
      transitionDuration: 300,
    },
  };

  const customTransition = 'transform 500ms ease-in-out';
  const renderTitle = data ? (
    <><div className="storySectionNav-left">
          <Link to="/category/gstv-satrang"><img src="assets/icons/e-paper-1.svg" alt="" />
          <span>GSTV શતરંગ</span></Link>
      </div>
      <div className="storySectionNav-right">
          <Link to="/category/gstv-satrang" style={{ display: 'block' }} className='custom-link-btn'><FontAwesomeIcon icon={faChevronRight} /></Link>
      </div>
    </>
  ) : null;
  const iconurl = UrlLists.catIcon;
  return (
    <>
    <br></br><br></br>
    <div className="storySectionNav">
    {renderTitle}
    </div>
    <div className="carousel-inner-top custom-carousel topcategorycarosal">      
    <Carousel
      responsive={responsive}
      partialVisible={true}
      customTransition={customTransition}
      autoPlay={false} 
      autoPlaySpeed={5000} 
      infinite={true}
    >
        {data ? (data.map((item, index) => (
        <div>
             
            <Link
                className="popupbtn"
                to={`/category/gstv-satrang/${item.slug}`}
              >
                <div className="web-story-content">
                  <div className="web-story-card">
                    <button className="popupbtn">
                      <div className="custom-webstory-image">
                        <img
                          src={`${iconurl}${item.icon}`}
                          className="blog-featured-img img-fluid"
                        />
                      </div>
                      <div className="webstory-title-content custom-gujrati-font">
                        <h4>{item.title}</h4>
                      </div>
                    </button>
                  </div>
                </div>
              </Link>
        </div>
        ))
        ) : (
          <>{UrlLists.Loader}</>
        )}
    </Carousel>
    </div>
    </>
  );
};

export default MyCarousel;
