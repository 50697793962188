import React, { useEffect, useState } from 'react';
import { submitImpressionData } from "../services/allapis";
import AdsScript from './Adsscript';

const NewsDescription = ({ description }) => {
    const [htmlContent, setHtmlContent] = useState('');
    const [firstParagraph, setFirstParagraph] = useState('');
    const [remainingContent, setRemainingContent] = useState('');

    useEffect(() => {
        // Sample HTML content
        const sampleHtml = description;
        let modifiedHtml = '';
        // Replace Twitter URLs with embedded tweet HTML
        modifiedHtml = sampleHtml.replace(
            /(https:\/\/twitter\.com\/([a-zA-Z0-9_]+)\/status\/([0-9]+))|(https:\/\/fb\.watch\/([a-zA-Z0-9_]+))|(https:\/\/www\.instagram\.com\/p\/([a-zA-Z0-9_-]+)\/?)|(https:\/\/www\.instagram\.com\/reel\/([a-zA-Z0-9_-]+)\/?)|(https:\/\/www\.youtube\.com\/watch\?v=([a-zA-Z0-9_-]+))|(https:\/\/[^ \n]+\.pdf)/g,
            (match, twitterUrl, twitterUsername, twitterStatusId, facebookWatchUrl, facebookWatchId, instagramPostUrl, _, __, instagramReelShortcode, youtubeUrl, youtubeVideoId, pdfUrl) => {
                if (twitterUrl) {
                  return `<blockquote class="twitter-tweet"><a data-height="100%" href="${twitterUrl}">${twitterUrl}</a></blockquote>`;
                } else if (facebookWatchUrl) {
                  return `<iframe style="width: 100%; height: 500px; border: none; overflow: hidden;" src="https://www.facebook.com/plugins/post.php?href=${facebookWatchUrl}" frameborder="0" scrolling="no"></iframe>`;
                } else if (instagramPostUrl) {
                  return `<blockquote class="instagram-media" data-instgrm-permalink="${instagramPostUrl}" data-instgrm-version="13"></blockquote>`;
                } else if (instagramReelShortcode) {
                  const instagramReelEmbedUrl = `https://www.instagram.com/p/${instagramReelShortcode}/embed`;
                  return `<iframe src="${instagramReelEmbedUrl}" width="400" height="500" frameborder="0" scrolling="no" allowtransparency="true"></iframe>`;
                } else if (youtubeVideoId) {
                  return `<iframe width="100%" height="400" src="https://www.youtube.com/embed/${youtubeVideoId}" frameborder="0" allowfullscreen></iframe>`;
                } else if (youtubeUrl) {
                  const videoIdMatch = youtubeUrl.match(/(?:\/|v=)([a-zA-Z0-9_-]+)/);
                  const videoId = videoIdMatch ? videoIdMatch[1] : '';
                  return `<iframe width="100%" height="400" src="https://www.youtube.com/embed/${videoId}" frameborder="0" allowfullscreen></iframe>`;
                } else if (pdfUrl) {
                  return `<iframe src="${pdfUrl}#toolbar=0&navpanes=0" width="100%" height="600px"></iframe>`;
                }
                return match; // If neither pattern matches, return the original match
            }
        );
        
        setHtmlContent(modifiedHtml);
    }, [description]);    
    
    useEffect(() => {
        const scriptUrls = [
          'https://platform.twitter.com/widgets.js',
          '//www.instagram.com/embed.js',
        ];
    
        const loadScripts = async () => {
          for (const url of scriptUrls) {
            await new Promise((resolve) => {
              const script = document.createElement('script');
              script.src = url;
              script.async = true;
              document.body.appendChild(script);
              script.onload = resolve;
              return () => {
                document.body.removeChild(script);
              };
            });
            await new Promise((resolve) => setTimeout(resolve, 500));
          }
        };
    
        loadScripts();
      }, []);
      
      useEffect(() => {
        // Extract first paragraph from HTML content
        const paragraphRegex = /<p[^>]*>.*?<\/p>/gs;
        const match = paragraphRegex.exec(htmlContent);
        if (match) {
            setFirstParagraph(match[0]);
            setRemainingContent(htmlContent.slice(match.index + match[0].length));
        }
    }, [htmlContent]);
    useEffect(() => {
      // Function to handle initial logging on page load
      const logInitialImpressions = async () => {
        try {  
          const impressions = [
            { imgvalue: 'Nmo_Lakshmi_Yojana' },
            { imgvalue: 'Cashless_sarvar' },
            { imgvalue: 'Nmo_Sarsavati_Yojana' },
          ].map(impression => ({ ...impression, currentPageUrl: window.location.href, click: 0 }));
    
          // Send the entire impressions array at once
          const result = await submitImpressionData(impressions);
        } catch (error) {
          console.error('Error in initial impression logging:', error);
        }
      };
  
      logInitialImpressions(); // Call the function immediately on page load
    }, []);
    const handleClick = async (imgvalue, redirectUrl) => {
      try {
        const impressions = [
          { imgvalue: imgvalue },
        ].map(impression => ({ ...impression, currentPageUrl: window.location.href, click: 1 }));
        // Await the result of the asynchronous function
        const result = await submitImpressionData(impressions);
  
        if (redirectUrl) {
          window.open(redirectUrl, '_blank');
        }
      } catch (error) {
        console.error('Error in handleClick:', error);
      }
    };
    return (
        <> 
        <h5 className="blog-excerpt detail-page">
          <div dangerouslySetInnerHTML={{ __html: firstParagraph }} />
          <AdsScript />
          {/* <div className="row blog-content mb-3 adscls">
            <div className="col-md-4"><a href="#" onClick={(e) => { e.preventDefault(); handleClick('Nmo_Lakshmi_Yojana', 'https://cmogujarat.gov.in/en'); }}><img src="/assets/graphics/img/04.jpg" className="gridimg custom-image lazyautosizes lazyloading" width="100%" /></a></div>
            <div className="col-md-4"><a href="#" onClick={(e) => { e.preventDefault(); handleClick('Cashless_sarvar', 'https://cmogujarat.gov.in/en'); }}><img src="/assets/graphics/img/05.jpg" className="gridimg custom-image lazyautosizes lazyloading" width="100%" /></a></div>
            <div className="col-md-4"><a href="#" onClick={(e) => { e.preventDefault(); handleClick('Nmo_Sarsavati_Yojana', 'https://cmogujarat.gov.in/en'); }}><img src="/assets/graphics/img/06.jpg" className="gridimg custom-image lazyautosizes lazyloading" width="100%" /></a></div>
          </div>
          <div className="row blog-content adsmobilecls">
            <div className="col-md-3"><a href="#" onClick={(e) => { e.preventDefault(); handleClick('Nmo_Sarsavati_Yojana', 'https://cmogujarat.gov.in/en'); }}><img src="/assets/graphics/img/06.jpg" className="gridimg custom-image lazyautosizes lazyloading" width="100%" /></a></div>
          </div> */}
          <div dangerouslySetInnerHTML={{ __html: remainingContent }} />
        </h5>
         {/* <h5 className="blog-excerpt detail-page" dangerouslySetInnerHTML={{ __html: htmlContent }} />  */}
        </>
    );
};

export default NewsDescription;
