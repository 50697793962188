import { useState, useEffect } from 'react';
import { formatDistanceToNow, format } from 'date-fns';

let apiUrl;
let domain;
if (typeof window !== 'undefined' && window.location.hostname === 'localhost') {
    // Client-side execution
    apiUrl = 'https://www.gstv.in/backend'; // Replace with your production URL
    domain = 'https://www.gstv.in';
} else {
    // Server-side execution or non-local client-side execution
    apiUrl = 'https://www.gstv.in/backend'; // Replace with your production URL
    domain = 'https://www.gstv.in';
}

export const UrlLists = {
  domainURL: `${domain}`,
  apiBaseUrl: `${apiUrl}/api`,
  logoURL: `${apiUrl}/public/uploads/logo/`,
  catIcon: `${apiUrl}/public/uploads/category/icon/`,
  newImg: `${apiUrl}/public/uploads/news/images/`,
  newVideo: `${apiUrl}/public/uploads/news/videos/`
};

export const BookmarkButton = ({ itemId, itemName }) => {
  const [isBookmarked, setIsBookmarked] = useState(false);

  useEffect(() => {
    // Check if the item is bookmarked on component mount
    const storedBookmarks = JSON.parse(localStorage.getItem('bookmarks')) || [];
    setIsBookmarked(storedBookmarks.includes(itemId));
  }, [itemId]);

  const handleBookmarkToggle = () => {
    // Toggle the bookmark state
    setIsBookmarked(!isBookmarked);

    // Retrieve and update the list of bookmarks from localStorage
    const storedBookmarks = JSON.parse(localStorage.getItem('bookmarks')) || [];
    const updatedBookmarks = isBookmarked
      ? storedBookmarks.filter((id) => id !== itemId)
      : [...storedBookmarks, itemId];

    // Save the updated list of bookmarks to localStorage
    localStorage.setItem('bookmarks', JSON.stringify(updatedBookmarks));
  };

  return (
    <button onClick={handleBookmarkToggle}>
      {isBookmarked ? 'Remove Bookmark' : 'Bookmark'}
    </button>
  );
};
export const truncateTitle = (text, maxWords) => {
  // const words = (removeHtmlTags(text) || '').trim().split(/\s+/);
  // const wordCount = words.length;
  // if (wordCount > maxWords) {
  //   return words.slice(0, maxWords).join(' ') + '...';
  // }
  return text;
};
export const ReadingTimeCalculator = ({ content, wordsPerMinute }) => {
  const [readingTime, setReadingTime] = useState(0);

  useEffect(() => {
    // Calculate the reading time when the component mounts
    const calculateReadingTime = () => {
      const word = content?.split(' ') || [];
      const words = word.filter((word) => word !== '').length;
      const time = Math.ceil(words / wordsPerMinute);
      setReadingTime(time);
    };

    calculateReadingTime();
  }, [content, wordsPerMinute]);

  return (
    <div className='custom-reading-time'>
      <div className='reading-icon'><img src="/assets/icons/clock.webp" alt="" /></div> {readingTime} min{readingTime !== 1 && 's'} read 
    </div>
  );
};

export const removeHtmlTags = (htmlString) => {
  const doc = new DOMParser().parseFromString(htmlString, 'text/html');
  return doc.body.textContent || "";
};

export const truncateDescription = (text, maxWords) => {
  const words = (removeHtmlTags(text) || '').trim().split(/\s+/);
  const wordCount = words.length;
  if (wordCount > maxWords) {
    return words.slice(0, maxWords).join(' ') + '...';
  }
  return text;
};

// export const DateComponent = ({ formattedDate }) => {
//   const date = new Date(formattedDate);
//   const distance = formatDistanceToNow(date, { addSuffix: true });

//   if (distance.includes('day')) {
//     const formattedDateTime = format(date, "dd MMM yyyy");
//     return formattedDateTime;
//   } else if (distance.includes('hours') || distance.includes('minutes')) {
//     const distanceWithoutAbout = distance.replace('about', '').trim();
//     if (distance.includes('minute')) {
//       const minutes = parseInt(distance);
//       return `${minutes} minutes ago`; // Return minutes in English
//     }
//     return distanceWithoutAbout;
//   } else {
//     // If it's not within 'day', 'hours', or 'minutes', return a default relative time representation
//     return distance.replace('about', '').trim();
//   }
// };

export const DateComponent = ({ formattedDate }) => {
  // return formattedDate;
  const date = new Date(formattedDate);
  const currentDate = new Date();
  const distance = formatDistanceToNow(date, { addSuffix: true });

  if (distance.includes('day')) {
    const formattedDateTime = format(date, "dd MMM yyyy");
    return formattedDateTime;
  } else if (distance.includes('hours') || distance.includes('hour') || distance.includes('minutes')  || distance.includes('minute')) {
    const distanceWithoutAbout = distance.replace('about', '').trim();
    if (distance.includes('minute')) {
      const minutes = parseInt(distance);
      return `${minutes} minutes ago`; // Return minutes in English
    }
    return distanceWithoutAbout;
  } else {
    // Check if the difference is more than 24 hours
      const formattedDateTime = format(date, "dd MMM yyyy");
      return formattedDateTime;

  }
};

export const Loader = () => {
  return <span className="loader"></span>;
};
