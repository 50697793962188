import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { getSettingCategory } from "../../services/allapis";
import { Link, useLocation } from "react-router-dom";

const Footer = () => {
  const [data, setData] = useState(null);
  const [isActive, setActive] = useState(false);
  const location = useLocation();
  useEffect(() => {
    const storedSetting = sessionStorage.getItem("AllSetting");
    if (storedSetting !== null) {
      const settingsdata = JSON.parse(storedSetting);
      setData(settingsdata);
    } else {
      const fetchDataFromApi = async () => {
        try {
          const result = await getSettingCategory();
          setData(result.setting);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      if (!data) {
        fetchDataFromApi();
      }
    }
  }, []);

  const handleToggle = () => {
    setActive(!isActive);
  };
  // Use optional chaining to avoid errors if data is null or undefined

  const footerScript = data?.[0]?.footerScript;

  return (
    <>
      {/* Adding script above </body> tag */}
      <Helmet>
        {footerScript && <script type="text/javascript">{footerScript}</script>}
      </Helmet>
      {/* <div className="custom-footer">
        <ul>
          <li>
            <Link to="/">
              <div className="footer-image">
                <img src="/assets/icons/f-home.svg" />
              </div>
              <p className="footer-link custom-gujrati-font">હોમ</p>
            </Link>
          </li>
          <li>
            <Link to="/web-stories">
              <div className="footer-image">
                <img src="/assets/icons/footer-webstorys.svg" />
              </div>
              <p className="footer-link custom-gujrati-font">વેબ સ્ટોરીઝ</p>
            </Link>
          </li>
          <li>
            <Link to="/category/videos">
              <div className="footer-image">
                <img src="/assets/icons/f-video.svg" />
              </div>
              <p className="footer-link custom-gujrati-font">વિડિયો</p>
            </Link>
          </li>
          <li>
            <Link to="/category/entertainment">
              <div className="footer-image">
                <img src="/assets/icons/f-entertainment.svg" />
              </div>
              <p className="footer-link custom-gujrati-font">એન્ટરટેઇનમેન્ટ</p>
            </Link>
          </li>
          
        </ul>
      </div> */}
      <div className="custom-footer">
        <div className="row footer-menu">
          <div className="col-3">
            <Link
              to="/"
              className={`${location.pathname === "/" ? "active" : ""}`}
            >
              <div className="footer-image">
                <img src="/assets/icons/f-home.svg" />
              </div>
              <p className="footer-link custom-gujrati-font">હોમ</p>
            </Link>
          </div>
          <div className="col-3">
            <Link
              to="/category/entertainment"
              className={`${
                location.pathname === "/category/entertainment" ? "active" : ""
              }`}
            >
              <div className="footer-image">
                <img src="/assets/icons/f-entertainment.svg" />
              </div>
              <p className="footer-link custom-gujrati-font">એન્ટરટેઇનમેન્ટ</p>
            </Link>
          </div>
          <div className="col-3">
            <Link
              to="/category/videos"
              className={`${
                location.pathname === "/category/videos" ? "active" : ""
              }`}
            >
              <div className="footer-image">
                <img src="/assets/icons/f-video.svg" />
              </div>
              <p className="footer-link custom-gujrati-font">વીડિયો</p>
            </Link>
          </div>
          <div className="col-3">
            <Link
              to="/web-stories"
              className={`${
                location.pathname === "/web-stories" ? "active" : ""
              }`}
            >
              <div className="footer-image">
                <img src="/assets/icons/footer-webstorys.svg" />
              </div>
              <p className="footer-link custom-gujrati-font">વેબ સ્ટોરીઝ</p>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
