import React, { useEffect, useState } from 'react';
import {Link}  from 'react-router-dom';
import { getSettingCategory, submitPollVote,submitImpressionData } from '../../services/allapis';

const Poll = () => {
  const [datapoll, setDataPoll] = useState(null);
  const [datamsg, setVoteData] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showMessage, setShowMessage] = useState(true);

  useEffect(() => {
    const storedPollData = sessionStorage.getItem("PollData");
    if (storedPollData !== null) {
      try {
        const pollsData = JSON.parse(storedPollData);
        setDataPoll(pollsData);
      } catch (error) {
        console.error('Error parsing JSON:', error);
        // Handle error gracefully, e.g., set default value or display error message
      }
    } else {
      const fetchData = async () => {
        try {
          const result = await getSettingCategory();
          setDataPoll(result.poll);
          localStorage.setItem('pollData', JSON.stringify(result.poll));
        } catch (error) {
          // Handle error
        }
      };

      if (!datapoll) {
        fetchData();
      } else {
        const cachedData = localStorage.getItem('pollData');
        if (cachedData) {
          try {
            const parsedData = JSON.parse(cachedData);
            setDataPoll(parsedData);
          } catch (error) {
            console.error('Error parsing JSON:', error);
            // Handle error gracefully, e.g., set default value or display error message
          }
        }
      }
    }
  }, []);

  const hideMessageAfterDelay = () => {
    setTimeout(() => {
      setShowMessage(false);
      setSelectedOption(null);
    }, 3000);
  };

  const handleVote = async (pollid, optionId) => {
    try {
      setSelectedOption(optionId);
      const result = await submitPollVote({
        pollID: pollid,
        userID: '',
        selectedAnswer: optionId,
        IPaddress: '',
      });
      setShowMessage(true);
      setVoteData(result);
      hideMessageAfterDelay();
    } catch (error) {
      // Handle error
    }
  };

  const pollid = datapoll?.[0]?.id || '';
  const question = datapoll?.[0]?.question || '';
  const pollanswerOption = datapoll?.[0]?.answerOption || '';
  const pollanswersList = pollanswerOption ? pollanswerOption.split(':') : [];
  const message = datamsg?.message;
  useEffect(() => {
    // Function to handle initial logging on page load
    const logInitialImpressions = async () => {
      try {
        const impressions = [
          { imgvalue: 'Vidhya_smiksha_candra' },
        ].map(impression => ({ ...impression, currentPageUrl: window.location.href, click: 0 }));
        // Send the entire impressions array at once
        const result = await submitImpressionData(impressions);
      } catch (error) {
        console.error('Error in initial impression logging:', error);
      }
    };

    logInitialImpressions(); // Call the function immediately on page load
  }, []);
  const handleClick = async (imgvalue, redirectUrl) => {
    try {
      const impressions = [
        { imgvalue: imgvalue },
      ].map(impression => ({ ...impression, currentPageUrl: window.location.href, click: 1 }));
      // Await the result of the asynchronous function
      const result = await submitImpressionData(impressions);

      if (redirectUrl) {
        window.open(redirectUrl, '_blank');
      }
    } catch (error) {
      console.error('Error in handleClick:', error);
    }
  };
  return (
    <>
      {datapoll && datapoll.length > 0 ? (
        <div className="MCQ">
          <h5 className="MCQ-Question custom-gujrati-font">{question}</h5>
          <div className="MCQ-options custom-gujrati-font">
            {showMessage && message && (
              <p className="MCQ-msg">{message}</p>
            )}
            {pollanswersList.map((item, index) => (
              <div key={index} className="all-options">
                <input
                  type="radio"
                  name="pollOption"
                  checked={selectedOption === item}
                  id={item}
                  value={item}
                  onChange={() => setSelectedOption(item)}
                  onClick={() => handleVote(pollid, item)}
                />
                <label htmlFor={item}>{item}</label>
              </div>
            ))}
            <Link className='poleres' to="poll-result">View Results</Link>
          </div>
        </div>
      ) : (
        <></>
      )}
      {/* <div className="col-md-12 text-center mt-3">
        <a href="#" rel="noopener noreferrer" onClick={(e) => { e.preventDefault(); handleClick('Vidhya_smiksha_candra', 'https://cmogujarat.gov.in/en'); }}>
          <img src="/assets/graphics/img/08.jpg" className="gridimg custom-image lazyautosizes lazyloading" style={{ maxWidth: '100%' }}  />
        </a>
      </div> */}
    </>
  );
};

export default Poll;
