import React, { useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import LazyLoad from 'react-lazyload';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlayCircle
} from "@fortawesome/free-solid-svg-icons";


const NewsImagesVideos = ({ videoURL, featureImage, Title, imgCls }) => {
    const playerRef = useRef(null);
    let clsname = '';
    const [isMuted, setIsMuted] = useState(true);     
    
    if (imgCls === 'slider') {
        clsname = 'd-block w-100';
    } else if (imgCls === 'innerpage') {
        clsname = 'blog-featured-img inner';
    } else if (imgCls === 'gridimg') {
        clsname = 'blog-featured-img img-fluid';
    }

    let fileExtension;
    let imgvideo = '';
    let fileNameWithoutExtension;
    let LazyImagevideo;

    if (featureImage !== undefined && featureImage != null) {
        fileExtension = featureImage.split('.').pop().toLowerCase();
    } else if (videoURL !== undefined && videoURL != null) {
        fileExtension = videoURL.split('.').pop().toLowerCase();
    } 

    if (videoURL!=null && featureImage!=null) {
        imgvideo = 'imagevideo';
    } else if (fileExtension === 'webp' || fileExtension === 'jpg' || fileExtension === 'jpeg' || fileExtension === 'png' || fileExtension === 'gif') {
        imgvideo = 'image';
    } else if (fileExtension === 'mp4' || fileExtension === 'm4v' || fileExtension === 'avi'  || fileExtension === 'webm' || fileExtension === 'mov' || fileExtension === 'wmv') {
        imgvideo = 'video';        
        const lastDotIndex = videoURL.lastIndexOf('.');
        if (lastDotIndex !== -1) {
            fileNameWithoutExtension = videoURL.substring(0, lastDotIndex);
            fileNameWithoutExtension = fileNameWithoutExtension + '_video.gif';
        }

        // Declare LazyImagevideo component
        LazyImagevideo = () => (
            <div className=''>
                <LazyLoad height={200} once>
                    <img src={fileNameWithoutExtension} alt={Title} className={clsname} />
                </LazyLoad>
                
            </div> 
        );
    } 

    const LazyImage = ({ src, alt, className }) => (
        <LazyLoad height={200} once>
            <img src={src} alt={alt} className="gridimg custom-image" />
        </LazyLoad>
    );  

    return (
        <>
        {imgvideo === 'imagevideo' ? (
            <>
            {imgCls === 'innerpage' ? (
                <ReactPlayer
                url={videoURL}
                controls={true}
                playing={true}
                muted={isMuted} 
                width="100%"
                height="100%"
                onPlay={() => setIsMuted(false)}
                style={{ pointerEvents: 'all', maxHeight: '500px' }}
                config={{
                    file: {
                        attributes: {
                            controlsList: 'nodownload'
                        }
                    }
                }}
            />
            ) : (
                <>
                <div className='detail-video-news'> 
                <LazyImage src={featureImage.replace(`.${fileExtension}`, `_small.${fileExtension}`)} alt={Title} className={clsname} />
                <div className='play-icon'><FontAwesomeIcon icon={faPlayCircle} /></div>
                </div>
                </>
            )}
            </>
           ) : imgvideo === 'image' ? (
                <>
                    {imgCls === 'innerpage' ? (
                        <LazyImage src={featureImage.replace(`.${fileExtension}`, `_medium.${fileExtension}`)} alt={Title} className={clsname} />
                    ) : (
                        <LazyImage src={featureImage.replace(`.${fileExtension}`, `_small.${fileExtension}`)} alt={Title} className={clsname} />
                    )}
                </>
            ) : (
                <div className='videonewscls'>
                    
                        {imgCls === 'innerpage' ? (
                            <ReactPlayer
                                url={videoURL}
                                controls={true}
                                playing={true}
                                muted={isMuted} 
                                width="100%"
                                height="100%"
                                onPlay={() => setIsMuted(false)}
                                style={{ pointerEvents: 'all' }}
                                config={{
                                    file: {
                                        attributes: {
                                            controlsList: 'nodownload'
                                        }
                                    }
                                }}
                            />
                        ) : (
                           LazyImagevideo && <LazyImagevideo />
                        )}
                    
                </div>
            )}
        </>
    );
};

export default NewsImagesVideos;
