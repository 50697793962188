import React, { useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { truncateTitle, UrlLists } from './../utils/utils';
import NewsImagesVideos from './Newsimg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlayCircle,
  faChevronRight
} from "@fortawesome/free-solid-svg-icons";
import { getTopSlider } from '../services/allapis';




const MyCarousel = () => {
    const [videodata, setData] = useState(null);
    useEffect(() => {
      const fetchDataFromApi = async () => {
        try {
          const result = await getTopSlider();
          setData(result);
        } catch (error) {
          // Handle error
          console.error("Error fetching data:", error);
        }
      };
  
      // Fetch data only if data is null
      if (!videodata) {
        fetchDataFromApi();
      }
    }, [videodata]);
  const data = videodata;
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      partialVisibilityGutter: 43, // Adjust this value as needed
      transitionDuration: 300,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      partialVisibilityGutter: 30, // Adjust this value as needed
      transitionDuration: 300,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
      partialVisibilityGutter: 5, // Adjust this value as needed
      transitionDuration: 300,
    },
  };

  const customTransition = 'transform 500ms ease-in-out';
  const renderTitle = data ? (
    <><div className="storySectionNav-left">
          <Link to="/category/videos"><img src="assets/icons/e-paper-1.svg" alt="" />
          <span>Videos</span></Link>
      </div>
      <div className="storySectionNav-right">
          <Link to="/category/videos" style={{ display: 'block' }} className='custom-link-btn'><FontAwesomeIcon icon={faChevronRight} /></Link>
      </div>
    </>
  ) : null;
  return (
    <>
    <div className="storySectionNav">
    {renderTitle}
    </div>
    <div className="carousel-inner-top custom-carousel">      
    <Carousel
      responsive={responsive}
      partialVisible={true}
      customTransition={customTransition}
      autoPlay={false} 
      autoPlaySpeed={5000} 
      infinite={true}
    >
        {data ? (data.map((item, index) => (
        <div>
            <Link to={`/videos/${item.slug}`}>
                <div className="card custom-card">
                    <div className="img-wrapper">
                    <NewsImagesVideos videoURL={item.videoURL} featureImage={item.featureImage} Title={item.title} imgCls="slider" />
                    <div className='play-icon'><FontAwesomeIcon icon={faPlayCircle} /></div>
                    </div>
                    <div className="card-bodys">
                        <span className="samachar-title">{item.title}</span> 
                    </div>
                </div>
            </Link>
        </div>
        ))
        ) : (
          <>{UrlLists.Loader}</>
        )}
    </Carousel>
    </div>
    </>
  );
};

export default MyCarousel;
