import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { submitLoginData } from "../services/allapis";

const Login = () => {
    const initialFormData = {
        mobile: ""
      };
  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
 
  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (!formData.mobile.trim()) {
      newErrors.mobile = "Mobile No is required.";
      isValid = false;
    } else if (!/^[0-9]*$/.test(formData.mobile.trim())) {
      newErrors.mobile = "Invalid Mobile No.";
      isValid = false;
    }  

    setErrors(newErrors);
    return isValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
     
      setFormData({
        ...formData,
        [name]: value,
      });
     
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const result = await submitLoginData(formData);
        setFormData(initialFormData);
        window.scrollTo(0, 0);
        setErrors({});
        if(result.success==="error"){
          setErrorMessage(result.msg);
        }
        else {
          setSuccessMessage(result.msg);
        }
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
        setTimeout(() => {
          setSuccessMessage("");
        }, 3000);
      } catch (error) {
        console.error("Error posting data:", error);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Login Page</title>
      </Helmet>
      <div className="login-container">
        <div className="logo">
          <img src="/assets/images/logo.png" alt="Logo" />
        </div>
        {successMessage && <div className="success-message succmsg">{successMessage}</div>}
        {errorMessage && <div className="success-message errormsg">{errorMessage}</div>}
        <form onSubmit={handleSubmit} className='loginForm'>
            <div className="login-form">
                <input
                    type="text"
                    name="mobile"
                    value={formData.mobile}
                    onChange={handleChange}
                    placeholder="Enter Mobile Number"
                    className="mobile-input"
                />
                {errors.mobile && <div className="error">{errors.mobile}</div>}
                <div className="row pb-4">
                    <div className="text-left">
                        <button type="submit" className="btnCls">submit</button>
                    </div>
                </div>
            </div>
        </form>
      </div>
    </>
  );
};

export default Login;
