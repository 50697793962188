import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { getSettingCategory } from "../../services/allapis";
import { Link, useLocation } from "react-router-dom";
import { UrlLists } from "../../utils/utils";

const Header = () => {
  const [data, setData] = useState(null);
  const location = useLocation();
  useEffect(() => {
    const storedSetting = sessionStorage.getItem("AllSetting");
    if (storedSetting !== null) {
      const settingsdata = JSON.parse(storedSetting);
      setData(settingsdata);
    } else {
      const fetchDataFromApi = async () => {
        try {
          const result = await getSettingCategory();
          sessionStorage.setItem("MenuCategory", JSON.stringify(result.category));
          sessionStorage.setItem("AllSetting", JSON.stringify(result.setting));
          sessionStorage.setItem("PollData", JSON.stringify(result.poll));
          setData(result.setting);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      if (!data) {
        fetchDataFromApi();
      }
    }
  }, []);
  
  
  const siteTitle = data?.[0]?.siteTitle;
  const metatitle = data?.[0]?.metatitle;
  const metakeyword = data?.[0]?.metakeyword;
  const metadesc = data?.[0]?.metadesc;
  const logo = data?.[0]?.logo;
  const logoURL = UrlLists.logoURL;
  const headerScript = data?.[0]?.headerScript;
  //const bodyScript = data?.[0]?.bodyScript;
  const footerScript = data?.[0]?.footerScript;

  const toplinks = [
    {
      path: "/",
      label: "હોમ",
      icon: "/assets/icons/header-home.svg",
      class: "nav-item nav-link",
    },
    {
      path: "/web-stories",
      label: "વેબ સ્ટોરીઝ",
      icon: "/assets/icons/header-webstorys.svg",
      class: "nav-item nav-link",
    },
    {
      path: "/category/videos",
      label: "વીડિયો",
      icon: "/assets/icons/header-video.svg",
      class: "nav-item nav-link",
    },
    //{ path: '/#', label: 'Photo', icon: '/assets/icons/schedule.svg', class:'nav-item nav-link' },
    {
      path: "/category/entertainment",
      label: "એન્ટરટેઇનમેન્ટ",
      icon: "/assets/icons/header-entertainment.svg",
      class: "nav-item nav-link",
    },
    // {
    //   path: "/web-stories",
    //   label: "Web Stories",
    //   icon: "/assets/icons/Vector.svg",
    //   class: "nav-item nav-link",
    // },
    //{ path: '/#', label: 'Photo', icon: '/assets/icons/grid.svg', class:'nav-item nav-link mobile-display-hide' },
    // { path: '/#', label: 'Photo', icon: '/assets/icons/setting.svg', class:'nav-item nav-link mobile-display-hide' },
    // { path: '/#', label: 'Photo', icon: '/assets/icons/user.svg', class:'nav-item nav-link mobile-display-hide' },
  ];
  
  return (
    <nav className="navbar navbar-expand-lg  bg-white fixed-top custom-header">
      <div className="container-fluid">
        <Helmet>
          {metatitle ? (
            <title>{`${metatitle} | ${siteTitle}`}</title>
          ) : (
            <title>
              Gujarati News - GSTV - ગુજરાત સમાચાર, Live TV, Gujarat Samachar
            </title>
          )}
          {metakeyword ? (
            <meta name="keyword" content={`${metakeyword}`} />
          ) : (
            <meta
              name="keyword"
              content="GSTV.in provides Gujarati News from across Gujarat, India & the world. Get headlines from Business, Bollywood, Cricket, Videos & LIVE TV"
            />
          )}
          {metadesc ? (
            <meta name="description" content={`${metadesc}`} />
          ) : (
            <meta
              name="description"
              content="GSTV.in provides Gujarati News from across Gujarat, India & the world. Get headlines from Business, Bollywood, Cricket, Videos & LIVE TV"
            />
          )}

          {headerScript && (
            <script type="text/javascript">{headerScript}</script>
          )}
        </Helmet>
        {/* Adding script above </body> tag */}
        <Helmet>
          {footerScript && (
            <script type="text/javascript">{footerScript}</script>
          )}
        </Helmet>
        <div className="row w-100 align-items-center">
          <div className="col-lg-2 col-md-2 col-sm-6 col-6 p-0">
            <a href="/" className="navbar-brand">
              {logo ? (
                <img
                  src={`${logoURL}${logo}`}
                  alt="GSTV.in"
                  className="logo img-fluid"
                />
              ) : (
                <img src="/assets/images/logo.webp" alt="GSTV.in" />
              )}
            </a>
          </div>
          <div className="col-lg-7 col-md-7 custom-navbar p-0">
            <div className={`navbar-collapse`}>
              <div className="navbar-nav ">
                {toplinks.map((links) => (
                  <Link
                    to={links.path}
                    className={`${links.class}${
                      location.pathname === links.path ? " active" : ""
                    }`}
                    rel="noopener noreferrer" 
                   // target="_blank" // Targeting blank
                  >
                    <img src={links.icon} alt={`${links.label}`} /><p className="main-header-title custom-gujrati-font">{links.label}</p>
                  </Link>
                ))}
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 col-6 p-0">
            <div className="profile-icons custom-profile-icons">
              {/* <a href="#" className="nav-item nav-link desktop-display-hide"><img src="/assets/icons/grid.svg" alt="" /></a>
      <a href="#" className="nav-item nav-link desktop-display-hide"><img src="/assets/icons/setting.svg" alt="" /></a>
      <a href="#" className="nav-item nav-link desktop-display-hide"><img src="/assets/icons/user.svg" alt="" /></a> */}
              <a href="https://akhbarokiduniya.com/" className="e-news-paper" rel="noopener noreferrer" target="_blank">
                <img src="/assets/icons/e-news-paper.svg" alt="E-news-paper" />
                <span className="hrader-title-text">ઈ-પેપર</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
